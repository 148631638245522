@import "../../common/variables"
@import "../../common/mixins"

.Description
  border-radius: 6px
  background-color: $color-primary
  padding: 20px
  color: #fff
  font-size: 14px
  line-height: 17px

.wdescription
  padding: 20px 21px
  background-color: var(--white-4)
  font-family: "MacPawFixelDisplay-Regular"
  font-size: 18px
  line-height: normal
  color: var(--primary-purple)
  border-radius: 5px
  margin: 25px 0 40px
