.legal {
  background-color: var(--white-3);
  padding: 35px 0 0;
}

@media (max-width: 768px) {
  .legal {
    padding: 19px 0 0;
  }
}

.legal__title {
  text-transform: uppercase;
  font-size: 40px;
  line-height: normal;
  font-family: "MacPawFixelDisplay-Bold";
  margin: 0 0 35px;
  text-align: center;
}

@media (max-width: 768px) {
  .legal__title {
    font-size: 20px;
    margin: 0 0 16px;
  }
}

.legal__item {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 4px 117px 0px rgba(0, 0, 0, 0.03);
  padding: 31px 16px 42px 0;
  display: flex;
  overflow: hidden;
  min-height: 953px;
}

@media (max-width: 992px) {
  .legal__item {
    flex-direction: column-reverse;
    margin: 0 -16px;
    padding-top: 30px;
    min-width: auto;
    gap: 20px;
  }
}

.legal__item--avoid {
  padding: 31px 35px 54px 54px;
}

@media (max-width: 768px) {
  .legal__item--avoid {
    margin: 0 -16px;
    padding: 31px 16px 54px 16px;
  }
}

.legal-nav {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 500px;
  width: 100%;
  padding: 0 50px;
  position: relative;
}

@media (max-width: 992px) {
  .legal-nav {
    order: 2;
    padding: 0px 0 0 16px;
    max-width: 100%;
    text-align: center;
    margin-bottom: 31px;
  }
}

.legal-nav::before {
  position: absolute;
  content: "";
  background-color: var(--grey-3);
  width: 1px;
  height: 120%;
  top: -31px;
  right: 0;
}

@media (max-width: 992px) {
  .legal-nav::before {
    position: absolute;
    content: "";
    background-color: var(--grey-3);
    width: 114%;
    height: 1px;
    top: unset;
    right: -15px;
    bottom: -31px;
  }
}

.legal-nav__title {
  font-size: 22px;
  line-height: normal;
  font-family: "MacPawFixelDisplay-SemiBold";
  margin: 0;
}

@media (max-width: 768px) {
  .legal-nav__title {
    font-size: 18px;
  }
}

.legal-nav__list {
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.legal-nav__link {
  font-size: 20px;
  line-height: normal;
  font-family: "MacPawFixelDisplay-Regular";
  color: var(--dark);
}
.legal-nav .active {
  position: relative;
  z-index: 12;
  color: var(--white);
}
.legal-nav .active::after {
  position: absolute;
  content: "";
  width: calc(100% + 30px);
  height: 50px;
  z-index: -1;
  top: 0;
  transform: translateY(-18%);
  left: -15px;
  background-color: var(--dark);
  color: var(--white);
  border-radius: 100px;
  font-family: "MacPawFixelDisplay-Black";
  padding: 10px 15px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
}

.legal-content {
  width: 670px;
  width: 100%;
  overflow-y: auto;
  padding: 0 20px 0 25px;
  max-height: 880px;
  font-family: "MacPawFixelDisplay-Regular";
  font-size: 20px;
  line-height: normal;
}

@media (max-width: 768px) {
  .legal-nav__link {
    font-size: 16px;
  }
  .legal-content {
    font-size: 16px;
  }
  .legal-nav .active::after {
    height: 40px;
    transform: translateY(-19%);
  }
}
@media (max-width: 992px) {
  .legal-content {
    padding: 0 24px 0 24px;
    max-width: 100%;
    max-height: 608px;
  }
}

@media (max-width: 768px) {
  .legal-content {
    padding: 0 8px 0 16px;
  }
}

.legal-content::-webkit-scrollbar {
  width: 6px;
}

.legal-content::-webkit-scrollbar-track {
  width: 2px;
  background-color: var(--white-3);
  opacity: 0.2;
}

.legal-content::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--primary-purple);
  width: 6px;
}
