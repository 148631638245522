@import "../../common/variables"
@import "../../common/mixins"

.Button
  display: inline-flex
  align-items: center
  text-align: center
  cursor: pointer
  justify-content: center
  margin: 0
  min-width: 170px
  padding: 14px 20px 15px
  border-radius: 6px
  font-size: 14px
  line-height: 17px
  text-transform: uppercase
  background-color: transparent
  color: $color-background
  border: 1px solid transparent

  &:hover
    filter: brightness(120%)
  &--Green
    background-color: $color-fourth
    color:#fff
  &--Red
    background-color: $color-second
    color: #fff

  &--White
    background-color: #fff
    color: $color-second

  &--Dark
    border-color: $color-background
    color: $color-background

  &--Blue
    border-color: $color-primary
    background-color: $color-primary
    color: #fff

  &--Rounded
    border-radius: 35px
    font-size: 12px
    line-height: 14px
    text-transform: none

  &--Medium
    font-size: 14px
    line-height: 17px
    padding: 11px 40px

  &--Small
    padding: 14px 28px
    min-width: auto

    +max-w($mobile_lg)
      padding: 14px

  &--Mini
    padding: 7px 15px
    min-width: auto

  .button-icon
    margin-right: 6px

  svg 
    width: 15px
    height: 15px
