.referal-main {
  color: var(--white);
  background-color: var(--dark);
}
.referal-main .h2 {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 96px;
  font-style: normal;
  line-height: 115%;
  text-transform: uppercase;
}
.refer {
  padding: 60px 0;
  position: relative;
  z-index: 1;
}

@media (max-width: 992px) {
  .refer {
    text-align: center;
    padding-bottom: 300px;
  }
}

@media (max-width: 768px) {
  .refer {
    padding-bottom: 249px;
  }
}

.refer::before {
  position: absolute;
  content: url(/img/refer-bg.png);
  bottom: -1px;
  right: 0;
  z-index: -1;
}

@media (max-width: 375px) {
  .refer::before {
    content: url(/img/refer-bg-mob.png);
  }
}

.refer::after {
  position: absolute;
  content: url(/img/refer-bg-2.png);
  bottom: 0;
  right: 50px;
  z-index: -1;
}

@media (max-width: 992px) {
  .refer::after {
    right: 0;
  }
}

@media (max-width: 768px) {
  .refer::after {
    content: url(/img/refer-bg-2-mob.png);
    right: 6px;
    bottom: 44px;
  }
}

.refer__title {
  margin: 0;
}

@media (max-width: 992px) {
  .refer__title {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .refer__title {
    font-size: 30px !important;
    text-align: center;
    margin: 0 0 6px;
  }
}

.refer__slogan {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
  margin-bottom: 29px;
}

@media (max-width: 768px) {
  .refer__slogan {
    font-size: 18px;
  }
}
/*--------------------------------------------------------------
# end refer
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# sing-up__referal
--------------------------------------------------------------*/
.sing-up__referal {
  background-image: url(/img/logo-bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 49px 0 57px;
}

@media (max-width: 768px) {
  .sing-up__referal {
    padding: 49px 0 55px;
  }
}

.sing-up-title {
  margin: 0 0 22px;
}

@media (max-width: 768px) {
  .sing-up-title {
    font-size: 30px !important;
    margin: 0 0 29px;
    text-align: center;
  }
}

.referal__btn {
  min-width: 183px;
}

.sing-up-form {
  padding: 35px 50px 55px;
  border-radius: 20px;
  background: linear-gradient(0deg, #121212 0%, #121212 100%), #fff;
  box-shadow: 0px 4px 117px 0px rgba(0, 0, 0, 0.03);
  text-align: left;
  width: 100%;
}

@media (max-width: 768px) {
  .sing-up-form {
    padding: 41px 24px;
    text-align: center;
  }
}

.sing-up-form__title {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-ExtraBold";
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 40px;
}

@media (max-width: 768px) {
  .sing-up-form__title {
    font-size: 20px;
    margin: 0 0 33px;
    text-align: left;
  }
}

.sing-up__box-label {
  display: flex;
  margin-bottom: 38px;
  gap: 56px;
}

@media (max-width: 992px) {
  .sing-up__box-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .sing-up__box-label {
    text-align: left;
    gap: 19px;
    margin-bottom: 18px;
  }
}

.sing-up__label {
  display: flex;
  flex-direction: column;
  max-width: 507px;
  width: 100%;
}

@media (max-width: 992px) {
  .sing-up__label {
    max-width: 100%;
  }
}

.sing-up__label span {
  font-family: "MacPawFixelDisplay-Light";
  font-size: 18px;
  opacity: 0.5;
  line-height: normal;
  color: var(--white);
  margin-bottom: 6px;
  padding-left: 47px;
}

.sing-up__label input {
  font-family: "MacPawFixelDisplay-Light";
  font-size: 22px;
  line-height: normal;
  color: var(--white);
  padding: 17px 31px;
  border-radius: 56px;
  border-radius: 56px;
  border: 1px solid var(--white);
  background-color: var(--dark-5);
  backdrop-filter: blur(20px);
}

.sing-up__label input::placeholder {
  font-family: "MacPawFixelDisplay-Regular";
  font-size: 20px;
  line-height: normal;
  color: var(--white);
}

.sing-up__label input:focus,
.sing-up__label select:focus {
  outline: none;
}

.sing-up__select {
  position: relative;
}

.sing-up__select select {
  position: absolute;
  font-size: 22px;
  width: 100%;
  border: none;
  height: 100%;
  background-color: transparent;
  background-image: url(/img/select-arrow.svg);
  background-position: 90% 63%;
  background-repeat: no-repeat;
  color: var(--white);
  font-weight: 600;
  top: 19px;
  left: 31px;
}

@media (max-width: 375px) {
  .sing-up__select select {
    background-position: 81% 64%;
  }
}

.sing-up__box-margin-55 {
  margin-bottom: 59px;
}

@media (max-width: 768px) {
  .sing-up__box-margin-55 {
    margin-bottom: 48px;
  }
}

.sing-up__box-margin {
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .sing-up__box-margin {
    margin-bottom: 40px;
  }
}

.captcha {
  margin-bottom: 49px;
}

@media (max-width: 768px) {
  .captcha {
    margin-bottom: 33px;
  }
}
.referal-main div:has(.dropdown) {
  width: 100% !important;
}
