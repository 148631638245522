// VARIABLE
$dashboard_padding: 40px 40px 40px 52px
$bottom_padding: 30px

// FONT FAMILY
$font-family-base: 'SFProDisplay', sans-serif
$font-family-second: 'FuturaPT', sans-serif
$font-weight-base: 400

// COLOR
$color-background: #121933
$color-background-second: #F5FAFF

$color-base: #fff
$color-text-base: $color-base
$color-text-second: $color-background

$color-primary: #5BA1FF
$color-second: #EF382A
$color-third: #212842
$color-fourth: #01C853
$color-fifth: #F7931A
$color-sixth: #1D2744
$color-seventh: #9A9A9A
$color-eighth: #BEBEBE
$color-dark-input-background: #30354A
$color-fogginess: #2E6DF6

// FONT
$font-size-base: 18px
$line-height-base: 21px

$desktop_lg: 2250
$desktop_md: 1920
$desktop_sm: 1700
$laptop_lg: 1440
$laptop_md: 1300
$laptop_sm: 1199
$tablet: 991
$mobile_lg: 767
$mobile_md: 539
$mobile_sm: 479
$mobile_xsm: 375
