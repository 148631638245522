.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: none;
  border: none;
  background-color: transparent;
  font-family: "MacPawFixelDisplay-ExtraBold";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  cursor: pointer;
  -webkit-appearance: button;
  appearance: button;
  min-width: 170px;
  gap: 10px;
  position: relative;
}

.button-primary {
  height: 67px;
  line-height: 67px;
  background-color: var(--primary-purple);
  color: var(--white);
  padding: 0 15px;
  border-radius: 1000px;
}
@media (max-width: 568px) {
  .button {
    line-height: normal;
  }
}
.button-primary svg {
  fill: white;
}
.button-primary .loading_bar path {
  stroke: white;
}
.button-secondary {
  height: 51px;
  line-height: 51px;
  color: var(--secondary-purple);
  padding: 0 4px;
}

.button-outlined {
  height: 67px;
  line-height: 67px;
  color: var(--primary-purple);
  padding: 0 15px;
  border-radius: 1000px;
  border: 1px solid rgba(84, 5, 214, 0.3);
}
.button-outlined .loading_bar path {
  stroke: var(--primary-purple);
}
.button-outlined:hover,
.button-outlined:focus {
  background: rgba(84, 5, 214, 0.05);
}
.button-secondary svg {
  transform: translateY(-2px);
}
.button-secondary:hover,
.button-secondary:focus {
  color: var(--secondary-purple-2);
  border-color: var(--secondary-purple-2);
}
.button-primary:hover,
.button-primary:focus {
  background-color: var(--primary-purple-2);
}

.button__faq-back {
  margin-right: auto;
  color: var(--dark);
  padding-left: 0;
  margin-left: -5px;
  min-width: auto;
}

.button__faq-back:hover path {
  stroke: var(--secondary-purple-2) !important;
}
.arrow-button:hover path,
.arrow-button:hover line {
  stroke: var(--secondary-purple-2);
}

.loading_bar {
  position: absolute;
  left: 14px;
  animation-name: loading_svg;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes loading_svg {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 460px) {
  .button-secondary {
    line-height: normal;
  }
}
.arrow-button__mini {
  display: none;
}
@media (max-width: 500px) {
  .arrow-button__mini {
    display: block;
  }
  .arrow-button__large {
    display: none;
  }
}
