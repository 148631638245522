@import "../../common/variables"
@import "../../common/mixins"

.Title
  font-weight: 900
  text-align: center

  &--H1
    font-size: 64px
    line-height: 76px

    +max-w($tablet)
      font-size: 36px
      line-height: 43px

  &--H2
    font-size: 64px
    line-height: 76px

    +max-w($tablet)
      font-size: 36px
      line-height: 43px
