@import "../../common/variables"
@import "../../common/mixins"

.Box
  background: $color-base
  box-shadow: 0px 4px 117px rgba(0, 0, 0, 0.03)
  border-radius: 20px
  padding: 30px
  display: flex

  +max-w($tablet)
    flex-direction: column

  +max-w($mobile_xsm)
    padding: 20px

.Box__Title
  text-transform: uppercase
  font-weight: 600
  color: $color-background
  margin-bottom: 40px

  +max-w($mobile_md)
    text-align: center

.Box__Left
  width: 100%
  padding-top: 10px
  padding-right: 148px

  +max-w($laptop_sm)
    padding-right: 5vw

  +max-w($tablet)
    margin-bottom: 40px
    padding-right: 0

.Box__Right
  width: 397px
  flex-grow: 1
  flex-shrink: 0

  +max-w($tablet)
    width: calc(100% + 38px)
    margin: 0 -24px

.Box__Row
  display: flex
  align-items: center
  margin-left: -10px
  margin-right: -10px

  +max-w($mobile_md)
    flex-direction: column

.Box__Col
  padding-left: 10px
  padding-right: 10px
  width: 100%

.Box__Label
  font-size: 14px
  line-height: 17px
  color: $color-seventh
  margin-bottom: 23px
