.about-us-wrapper .header {
    background-image: url(/img/about-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--white);
    padding: 55px 0 38px;
}

@media (max-width: 768px) {
    .about-us-wrapper .header {
        padding: 45px 0 27px;
        background-image: url(/img/about-bg-mob.png);
    }
}
.about-us-wrapper .h2 {
    font-family: "MacPawFixelDisplay-Bold";
    font-size: 96px;
    font-style: normal;
    line-height: 115%;
    text-transform: uppercase;
    margin: 0;
}
.nav__pages-about {
    margin: 0 40px;
}

.about-us-wrapper .nav a {
    color: var(--white);
    font-family: "MacPawFixelDisplay-Regular";
}
.about-us-wrapper .about-us-main {
    background-color: var(--white-3);
}
.nav__about-link:first-child {
    color: var(--white);
}

.nav__about-link:last-child {
    color: var(--white);
}

.header__form-about {
    margin-right: 27px;
    margin-left: auto;
    position: relative;
}

.header__form-about::before {
    position: absolute;
    content: url(/img/flag-1.svg);
    top: 8px;
    left: 0;
}

.exhange-field-about select {
    width: 137px;
    border: none;
    min-width: 90px;
    padding: 13px 16px 11px 28px;
    font-size: 18px;
    height: 100%;
    background-color: transparent;
    background-image: url(/img/select-arrow.svg);
    background-position: 91% 81%;
    background-repeat: no-repeat;
    color: var(--white);
    font-family: "MacPawFixelDisplay-Bold";
}

.exhange-field-about select:focus {
    outline: none;
}

.about-title {
    padding-top: 330px;
}

@media (max-width: 768px) {
    .about-title {
        padding-top: 246px;
        font-size: 30px !important;
        text-align: center;
    }
}
/*--------------------------------------------------------------
# end header
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# about
--------------------------------------------------------------*/

.about {
    padding: 35px 0 50px;
}

@media (max-width: 768px) {
    .about {
        padding: 33px 0 50px;
    }
}

.about-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    list-style: none;
    margin: 0 0 48px;
    padding: 0;
    gap: 42px;
}

@media (max-width: 992px) {
    .about-list {
        grid-template-columns: repeat(3, 1fr);
        gap: 60px 35px;
        justify-items: center;
    }
}

@media (max-width: 768px) {
    .about-list {
        grid-template-columns: repeat(1, 1fr);
        gap: 44px;
        justify-items: center;
        margin: 0 0 30px;
        text-align: center;
    }
}

.about-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 198px;
    width: 100%;
    text-align: center;
    gap: 32px;
}

@media (max-width: 768px) {
    .about-list__item {
        gap: 11px;
    }
}

.about-list__title {
    font-size: 22px !important;
    font-family: "MacPawFixelDisplay-Bold";
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

.about__descript {
    display: flex;
    gap: 30px;
}

@media (max-width: 768px) {
    .about__descript {
        flex-direction: column;
        gap: 6px;
    }
}

.about__text {
    margin-top: 36px;
    width: 100%;
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Regular";
    line-height: normal;
}

@media (max-width: 768px) {
    .about__text {
        font-size: 18px;
        max-width: 100%;
    }
}
/*--------------------------------------------------------------
# end about
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# avail
--------------------------------------------------------------*/
.avail {
    padding: 50px 0;
}

@media (max-width: 768px) {
    .avail {
        padding: 38px 0;
    }
}

.avail__title {
    margin: 0 0 19px !important;
}

@media (max-width: 768px) {
    .avail__title {
        font-size: 30px !important;
        text-align: center;
        margin: 0 0 20px;
    }
}

.avail__content {
    display: flex;
    gap: 30px;
}

@media (max-width: 992px) {
    .avail__content {
        flex-direction: column;
    }
}

.avail-item {
    border-radius: 20px;
    background-color: var(--white);
    box-shadow: 0px 4px 117px 0px rgba(0, 0, 0, 0.03);
    padding: 17px 30px;
    max-width: 570px;
    width: 100%;
}

@media (max-width: 992px) {
    .avail-item {
        max-width: 100%;
        padding: 11px 22px;
    }
}

.avail__item:nth-child(2) {
    background-color: var(--primary-purple);
    border-radius: 20px;
    color: var(--white);
    padding: 17px 30px;
}

@media (max-width: 992px) {
    .avail__item:nth-child(2) {
        order: -1;
        padding: 25px 22px;
    }
}

.avail__item:nth-child(2) select {
    color: var(--white);
    background-image: url(/img/select-arrow.svg);
}

.avail-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.avail-form__title {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Bold";
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

@media (max-width: 768px) {
    .avail-form__title {
        font-size: 20px;
    }
}

.exhange-field-about__btc select {
    width: 90px;
    color: var(--dark);
    background-image: url(/img/select-arrow-dark.svg);
}

.avail-item__list {
    display: flex;
    flex-direction: column;
    gap: 21px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.avail__item:nth-child(2) .avail-item__list {
    gap: 6px;
}

.avail-item__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.avail-item__item svg {
    min-width: 58px;
}

.avail-item__box {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: fit-content;
    width: 100%;
}

.avail-item__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

@media (max-width: 768px) {
    .avail-item__block {
        flex-direction: column;
        /* text-align: left; */
        align-items: flex-start;
        gap: 0;
    }
}

.avail-item__box--left {
    max-width: fit-content;
    width: 100%;
}

.avail-item__box-soon {
    max-width: 207px;
    width: 100%;
}

.avail-item__img {
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.2));
    line-height: normal;
}

.avail-item__box span {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Regular";
    line-height: normal;
}

@media (max-width: 768px) {
    .avail-item__box span {
        font-size: 17px;
    }
}

.avail-item__box p {
    font-size: 18px;
    font-family: "MacPawFixelDisplay-ExtraBold";
    line-height: normal;
    margin: 0;
}

@media (max-width: 768px) {
    .avail-item__box p {
        font-size: 17px;
    }
}

.avail-item__soon {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Bold";
    line-height: normal;
    padding-left: 20px;
}

@media (max-width: 768px) {
    .avail-item__soon {
        font-size: 18px;
    }
}
/*--------------------------------------------------------------
# end avail
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# benef
--------------------------------------------------------------*/
.benef {
    padding: 56px 0 0;
}

@media (max-width: 768px) {
    .benef {
        padding: 62px 0 0;
    }
}

.benef__title {
    margin: 0 0 27px !important;
}

@media (max-width: 768px) {
    .benef__title {
        font-size: 30px !important;
        text-align: center;
    }
}

.benef-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 62px 94px;
    list-style: none;
    margin: 0;
    padding: 0;
}

@media (max-width: 992px) {
    .benef-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .benef-list {
        grid-template-columns: repeat(1, 1fr);
        gap: 37px;
    }
}

.benef-list__item {
    max-width: 317px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

@media (max-width: 768px) {
    .benef-list__item {
        max-width: 100%;
        gap: 20px;
    }

    .benef-list__item svg {
        margin: 0 auto;
    }
}

.benef-list__title {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-ExtraBold";
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

@media (max-width: 768px) {
    .benef-list__title {
        font-size: 20px !important;
        text-align: center;
    }
}

.benef-list__text {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Regular";
    line-height: normal;
    margin: 0;
    max-width: 262px;
    width: 100%;
}

@media (max-width: 768px) {
    .benef-list__text {
        max-width: 100%;
        text-align: center;
    }
}

.benef-list__var {
    max-width: 317px;
    width: 100%;
}

@media (max-width: 768px) {
    .benef-list__var {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .benef-list__text {
        font-size: 18px;
    }
}
/*--------------------------------------------------------------
# endbenef
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# safe
--------------------------------------------------------------*/
.safe {
    padding: 29px 0 55px;
}

@media (max-width: 768px) {
    .safe {
        padding: 93px 0 156px;
    }
}

.safe__title {
    margin: 0 0 27px !important;
}

@media (max-width: 768px) {
    .safe__title {
        font-size: 30px !important;
        text-align: center;
        margin: 0 0 29px;
    }
}

.safe__slogan {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-bottom: 23px;
}

@media (max-width: 992px) {
    .safe__slogan {
        grid-template-columns: repeat(1, 1fr);
        gap: 9px;
    }
}

.safe__item {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Regular";
    line-height: normal;
}

@media (max-width: 768px) {
    .safe__item {
        font-size: 18px;
    }
}

.safe__btn-box {
    margin-top: 12px;
}

@media (max-width: 768px) {
    .safe__btn-box {
        margin-top: 28px;
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .safe__btn {
        display: block;
        text-align: center;
    }

    .safe__btn svg {
        transform: translateY(-15px);
    }
}

.safe-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

@media (max-width: 992px) {
    .safe-list {
        grid-template-columns: repeat(2, 1fr);
        gap: 18px;
    }
}

@media (max-width: 768px) {
    .safe-list {
        grid-template-columns: repeat(1, 1fr);
        margin: 0 -16px;
    }
}

.safe-list__item {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 437px;
    border-radius: 20px;
    box-shadow: 0px 4px 117px 0px rgba(0, 0, 0, 0.03);
}

@media (max-width: 768px) {
    .safe-list__item {
        height: 427px;
    }
}

.safe-list__item:nth-child(1) {
    background-image: url(/img/safe-1.png);
}

.safe-list__item:nth-child(2) {
    background-image: url(/img/safe-2.png);
}

.safe-list__item:nth-child(3) {
    background-image: url(/img/safe-3.png);
}

.safe-list__block {
    padding: 35px 25px;
}

@media (max-width: 768px) {
    .safe-list__block {
        padding: 27px 28px;
    }
}

.safe-list__title {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-ExtraBold";
    line-height: normal;
    margin: 0 0 5px;
    min-height: 60px;
}

@media (max-width: 768px) {
    .safe-list__title {
        font-size: 20px;
        min-height: auto;
        margin: 0 0 16px;
    }
}

.safe-list__text {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Regular";
    line-height: normal;
    margin: 0;
}

@media (max-width: 768px) {
    .safe-list__text {
        font-size: 18px;
    }
}

.safe-btn {
    min-width: 232px;
    position: absolute;
    bottom: 31px;
    right: 137px;
}

@media (max-width: 768px) {
    li:has(> .safe-btn) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .safe-btn {
        position: static;
        display: flex;
    }
}

.about-us-main .b-select {
    justify-content: flex-end;
    padding-right: 35px;
    border: none;
}
.blue-avail .b-select span {
    color: var(--white);
}
.blue-avail .b-select path {
    fill: var(--white);
    stroke: var(--white);
}
.blue-avail .b-select:hover {
    background-color: #8248e1 !important;
    color: black !important;
}

.avail-form:first-child .show:not(ul) {
    background-color: var(--b-select-hover-light);
}

.blue-avail .show:not(ul) {
    background-color: #8248e1 !important;
}
.about-us-main .b-select:first-child {
    background-color: transparent;
}
@media (max-width: 768px) {
    .about-us-wrapper .nav__content {
        background-color: var(--white-3);
        position: absolute;
    }
    .about-us-wrapper .nav__content .nav__link {
        color: var(--dark);
    }
    .about-us-wrapper .nav__content .nav__auth-link {
        color: var(--dark);
    }

    .about-us-wrapper .nav__content .nav__auth .b-select span {
        color: var(--dark);
    }
}
