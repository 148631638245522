@import "../../../common/variables"
@import "../../../common/mixins"

.verification-info
    position: absolute
    background-color: var(--white-4)
    border: 1px solid var(--secondary-purple-3)
    width: 100%
    border-radius: 15px
    padding: 10px
    bottom: -97%
    right: 0
    z-index: 1
    text-align: left
    &::before
        position: absolute
        content: ''
        width: 15px
        height: 15px
        background-color: var(--white-4)
        border: 1px solid var(--secondary-purple-3)
        border-bottom: none
        border-right: none
        border-radius: 2px
        transform: rotate(49deg)
        top: -8px
        right: 15px

@media (max-width: 768px)
    .verification-info
        bottom: -90%
        right: 10px

@media (max-width: 540px)
    .verification-info
        bottom: -106%
        right: 0

@media (max-width: 375px)
    .verification-info
        bottom: -133%

.verification-info__text
    font-size: 22px
    line-height: normal
    font-family: 'MacPawFixelDisplay-Regular'
    color: var(--primary-purple)
    margin-bottom: 15px

@media (max-width: 768px)
    .verification-info__text
        font-size: 18px

.verification-info__list
    margin: 0
    padding: 0
    list-style: none
    display: flex
    flex-direction: column
    gap: 5px

    & li
        font-size: 22px
        line-height: normal
        font-family: 'MacPawFixelDisplay-Regular'
        color: var(--primary-purple)
        position: relative
        padding-left: 10px

@media (max-width: 768px)
    .verification-info__list li
        font-size: 18px

.verification-info__list li::before
    position: absolute
    content: ''
    width: 2px
    height: 2px
    background-color: var(--primary-purple)
    border-radius: 50%
    top: 15px
    left: 3px

.verification-example
    margin-bottom: 36px

    &__list
        list-style: none
        margin: 0
        padding: 2px 0 0
        display: grid
        grid-template-columns: 186px auto
        gap: 36px 83px
        max-width: 570px
        width: 100%
        +max-w(1200)
            gap: 36px
        +max-w(992)
            justify-content: center
            max-width: 100%
            grid-template-columns: repeat(2, 1fr)

@media (max-width: 992px)
    .verification-example__list
        grid-template-columns: repeat(1, 1fr)
        gap: 24px
        text-align: left

.verification-example__item
    display: flex
    gap: 16px
    font-size: 16px
    line-height: normal
    font-family: 'MacPawFixelDisplay-Regular'
    color: var(--dark)
    align-items: center
    & span
        display: inline-flex
        justify-content: center
        align-items: center
        text-align: center
        border-radius: 50%
        background-color: var(--dark)
        color: var(--white)
        min-width: 23px
        height: 23px
.Modal__Description
    font-size: 22px
    +max-w(768)
        margin-bottom: 30px
.Modal__Button
    max-width: 100%
    gap: 16px
    display: flex !important
    +max-w(768)
        flex-direction: column
.document_upload_skip
    color: var(--primary-purple)
    font-family: 'MacPawFixelDisplay-Medium'
    font-size: 20px
    cursor: pointer
    line-height: 60px
    &:hover
        text-decoration: underline
