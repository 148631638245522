@import "../../../common/variables"
@import "../../../common/mixins"

.home_section
  background: var(--dark) !important
  // padding-bottom: 56px
.hero-container
  background: url(/img/main-bg.png) center -156px no-repeat
  background-size: initial
  +max-w(992)
    padding: 46px 0 55px
    background: transparent url(/img/main-bg-mob.png) center -104px no-repeat
