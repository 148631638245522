.nav__pages-countries {
  margin: 0 40px;
}

.header__form-countries {
  margin-right: 27px;
  margin-left: auto;
  position: relative;
}

.countries {
  padding: 3px 0 55px;
  background-color: var(--dark);
  color: var(--white);
}

.countries__title {
  font-size: 64px;
  margin: 0 0 6px;
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 96px;
  font-style: normal;
  line-height: 115% !important;
  text-transform: uppercase;
  margin: 0;
}

@media (max-width: 768px) {
  .countries__title {
    font-size: 30px !important;
    text-align: center;
    margin: 0 0 13px;
  }
}

.countries__info {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (max-width: 768px) {
  .countries__info {
    /* padding-left: 28px; */
    justify-content: center;
  }
}

.countries__text {
  font-size: 18px;
  font-family: "MacPawFixelDisplay-Regular";
  color: var(--error);
  line-height: normal;
  margin: 0;
}
.countries .support {
  color: var(--green);
}
.countries-form {
  margin-bottom: 52px;
  padding-left: 8px;
  margin-top: 32px;
}

.countries-form__block {
  display: flex;
  gap: 28px;
  align-items: end;
}

@media (max-width: 768px) {
  .countries-form__block {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.countries__tabs {
  margin-bottom: 38px;
  padding-top: 54px;
  width: 100%;
}

.countries__nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 24px;
  align-items: center;
}

@media (max-width: 992px) {
  .countries__nav {
    flex-direction: column;
    justify-content: left;
    text-align: left;
    align-items: flex-start;
  }
}

.countries__item span {
  padding: 8px 10px;
  text-align: center;
  font-size: 18px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
  cursor: pointer;
  color: var(--white);
}

@media (max-width: 992px) {
  .countries__item span {
    text-align: left;
  }
}

.countries__item span.active {
  border-radius: 100px;
  border: 1px solid var(--white);
}

.countries__table {
  margin-bottom: 16px;
}

.table__mob-btn {
  display: none;
}

.table__mob-block {
  display: none;
}

@media (max-width: 992px) {
  .table__mob {
    display: none;
  }
}

@media (max-width: 992px) {
  .table__mob-btn {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 38px;
    height: 37px;
    cursor: pointer;
  }
}

@media (max-width: 992px) {
  .table__mob-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    display: none;
    margin-top: 20px;
  }
}

.table__mob-countrie {
  display: flex;
  gap: 10px;
}

.table__mob-box {
  display: flex;
  gap: 64px;
}
.table__mob-box img {
  width: 16px;
  height: 16px;
  margin: 4px auto;
}
.table__mob-span {
  font-size: 18px;
  opacity: 0.5;
  display: block;
  color: var(--white) !important;
}

.table__mob-btn-x {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media (max-width: 992px) {
  .countries .show {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .countries .show-open {
    display: none;
  }
}
.countries {
  padding-top: 45px;
}
