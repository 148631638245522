@import "../../common/variables"
@import "../../common/mixins"

.Table
  display: table
  width: 100%
  table-layout: fixed
  border-collapse: collapse

.Table__Col-Group
  display: table-column-group

.Table__Col
  display: table-column

.Table__Head
  display: table-header-group
  font-size: 14px
  line-height: 17px
  padding: 20px

.Table__Body
  display: table-row-group

.Table__Row
  display: table-row
  border-radius: 16px

.Table__Cell
  display: table-cell
  border: none
  padding: 20px 40px
  vertical-align: middle

  &--Dark
    background: #222E52

  &--Grey
    height: 40px !important
    font-family: "ChakraPetch-Regular"
    font-size: 16px
    line-height: 24px
    // padding: 20px

  &:first-child
    border-radius: 16px 0 0 16px

  &:last-child
    border-radius: 0 16px 16px 0

  .Table__Head &
    background-color: transparent

  .Transactions__Table-Head &
    padding: 0 20px 20px
    color: $color-seventh

  &.Table__Cell__Break
    word-break: break-all

.Table__Separator
  margin-bottom: 30px

  &:last-child
    margin-bottom: 0
