@import "../../common/variables"
@import "../../common/mixins"

.selector-custom
  font-family: "ChakraPetch-SemiBold", Arial, Helvetica, sans-serif
  font-size: 14px
  color: #0d1118
  outline: none
  height: 40px
  line-height: 38px
  padding: 0 0 0 20px
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer
  width: fit-content
  gap: 10px
  max-width: 100%
  &__list
    border: 1px solid red
    @apply flex flex-col
    &-wrapper
      border: 1px solid red
      flex: 1 1 0
      overflow-y: auto
      background-color: white
  & .dropdown-icon
    padding-right: 22px
    flex-shrink: 0
  & > div:first-child
    display: flex
    align-items: center
    gap: 10px
    width: 100%
    max-width: calc(100% - 40px)
    & > span
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  &__text
    padding: 0 20px 0 0
    & input
      appearance: none
      background-color: transparent
      max-width: 20px

  &__outlined
    border: 1px solid #c3ced7
    &:hover
      border: 1px solid #c3ced7
  &__contained
    @apply border border-[#c3ced7] bg-white
    &:hover
      @apply border-border-light
.select-custom__list
  background-color: white
  box-shadow: 25px 25px 40px 0 rgba(16, 39, 83, 0.1)
  z-index: 100000
  display: flex
  flex-direction: column
  border: 1px solid #c3ced790
  height: auto
  & input
    width: 100%
    height: 41px
    font-size: 14px
    line-height: 20px
    font-family: ChakraPetch-Regular
    padding: 0 20px
    border-width: 0
    border-bottom-width: 0px
    border-bottom: 1px solid #e5e7eb
    &::placeholder
      color: #e5e7eb !important
      opacity: 1
  &-item
    font-family: "ChakraPetch-SemiBold", Arial, Helvetica, sans-serif
    font-size: 14px
    line-height: 14px
    color: #0d1118
    padding: 10px 20px
  &-item:hover:not(.selector-custom__selected)
    color: white
    cursor: pointer
    @apply bg-main-light

.selector-custom__selected
  color: #2048e6 !important
  cursor: pointer
.selector-custom__full-width
  width: 100%
.select-custom__list .select-custom__list-item:first-child
  margin-top: 10px
.select-custom__list .select-custom__list-item:not(:first-child):hover
  background-color: rgba(63, 72, 204, 0.867)

.select-custom__list .select-custom__list-item:last-child
  margin-bottom: 10px
.selector-custom__icon--up
  transform: rotate(180deg)
.custom-autocomplete
  &__single-value
    font-size: 14px
    color: black !important
    font-weight: 400
    & > div
      display: flex
      align-items: center
  &__option
    font-family: 'ChakraPetch-SemiBold', Arial, Helvetica, sans-serif
    cursor: pointer !important
    &:hover
      background-color: rgba(32, 72, 230, 0.0666666667) !important
    &--is-selected
      background-color: transparent !important
      color: #2048E6 !important

  &__menu
    box-shadow: 25px 25px 40px 0 rgba(16, 39, 83, 0.28) !important
    @apply border border-border-main
