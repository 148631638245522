@import "../../common/variables"
@import "../../common/mixins"

.custom_stepper
    display: flex
    align-items: flex-start
    justify-content: center
    .stepper-item
        text-align: center
        max-width: 160px
        min-width: 160px
        font-size: 12px
        line-height: 16px
        font-family: "ChakraPetch-SemiBold"
        color: #777E8A
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        &:not(:last-of-type)
            position: relative
            margin-right: 88px
            +max-w($mobile_md)
                margin-right: 40px
            & span:first-of-type::after
                position: absolute
                content: ""
                width: calc(100% + 44px)
                height: 1px
                border-bottom: 2px solid #68E063
                left: calc(50% + 24px)
                +max-w($mobile_md)
                    width: calc(100% - 8px)
        & span
            display: inline-flex
            &:first-of-type
                margin-bottom: 10px
                width: 24px
                height: 24px
                border-radius: 16px
                align-items: center
                justify-content: center
                box-shadow: inset 0 0 0 2px #68E063
                color: #68E063
            &:last-of-type
                +max-w($mobile_md)
                    display: block
                    max-width: 140px
        &.active
            span:last-of-type
                color: #3F48CC
            span:first-of-type
                background: #3F48CC
                color: white
                box-shadow: none
        &.active:not(:last-of-type)
            span:first-of-type::after
                position: absolute
                content: ""
                width: calc(100% + 44px)
                height: 1px
                border-bottom: 2px solid #3F48CC
                left: calc(50% + 24px)
                +max-w($mobile_md)
                    width: calc(100% - 8px)