@import "../../common/variables"
@import "../../common/mixins"

.Form__Box
  box-shadow: 0px 4px 117px rgba(0, 0, 0, 0.03)
  border-radius: 41px
  background: #fff
  padding: 40px

  +max-w($mobile_md)
    padding: 30px 40px
    display: flex
    flex-direction: column

.Form__Row
  display: flex
  margin: 0 -15px
  +max-w($mobile_md)
    flex-direction: column

  &:last-child
    margin-bottom: 0

  &--Sm
    margin: 0 -8px

  &--mb-15
    margin-bottom: 15px

    +max-w($mobile_md)
      margin-bottom: 0

  &--mb-40
    margin-bottom: 35px

    +max-w($mobile_md)
      margin-bottom: 0

  &--mb-60
    margin-bottom: 55px

    +max-w($mobile_md)
      margin-bottom: 0

.Form__Col
  padding: 0 15px
  width: 100%

  &--Sm
    padding: 0 8px

  +max-w($mobile_md)
    display: flex
    justify-content: center
    flex-direction: column
    margin-bottom: 30px

  &--Auto-Width
    width: auto

.Form__Link
  font-size: 12px
  margin-bottom: 25px
  display: inline-block

.Form__Title
  font-weight: 600
  font-size: 18px
  line-height: 21px
  margin-bottom: 40px

.Form__CheckboxSingle
  margin-bottom: 25px

.Form__Captcha
  margin-bottom: 40px

  +max-w($mobile_xsm)
    .Recaptcha
      transform: scale(0.85) !important
      transform-origin: 0 0 !important

.Form__CheckboxWrap
  display: flex
  align-items: center

.Form__Checkbox
  &:first-child
    margin-right: 25px

.Form__CheckboxLabel
  color: $color-seventh
  font-size: 14px
  line-height: 24px
  margin-bottom: 18px

.Form__Text
  font-weight: 600
  font-size: 18px
  line-height: 21px

.exhange-field
  border-radius: 56px
  height: 67px
  line-height: 67px
  display: flex
  align-items: center
  background: linear-gradient(#000, #000) padding-box,linear-gradient(to right, var(--primary-purple), #d9d9d9) border-box
  border: 1px solid transparent
  padding-top: 6px
  & input
    font-size: 18px
    margin-right: 20px
    width: 100%
    border-top-left-radius: 56px
    border-bottom-left-radius: 56px
    border: none
    background: transparent
    height: 100%
    padding: 0 0 0 32px
    color: var(--white)
