@import "../common/variables"
@import "../common/mixins"

+font-face('SFProDisplay', '../../Fonts/SFProDisplay/SFProDisplay-Thin', $weight: 300, $style: normal, $exts: woff2 woff)
+font-face('SFProDisplay', '../../Fonts/SFProDisplay/SFProDisplay-Regular', $weight: 400, $style: normal, $exts: woff2 woff)
+font-face('SFProDisplay', '../../Fonts/SFProDisplay/SFProDisplay-Medium', $weight: 500, $style: normal, $exts: woff2 woff)
+font-face('SFProDisplay', '../../Fonts/SFProDisplay/SFProDisplay-Semibold', $weight: 600, $style: normal, $exts: woff2 woff)
+font-face('SFProDisplay', '../../Fonts/SFProDisplay/SFProDisplay-Semibold', $weight: 600, $style: normal, $exts: woff2 woff)
+font-face('SFProDisplay', '../../Fonts/SFProDisplay/SFProDisplay-Bold', $weight: 700, $style: normal, $exts: woff2 woff)
+font-face('SFProDisplay', '../../Fonts/SFProDisplay/SFProDisplay-Black', $weight: 900, $style: normal, $exts: woff2 woff)

+font-face('FuturaPT', '../../Fonts/FuturaPT/FuturaPT-Bold', $weight: 900, $style: normal, $exts: woff2 woff)

// +font-face('MacPawFixelDisplay-Thin', '../../Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Thin', $weight: 300, $style: normal, $exts: woff2 woff)
// +font-face('MacPawFixelDisplay-Regular', '../../Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Regular', $weight: 400, $style: normal, $exts: woff2 woff)
// +font-face('MacPawFixelDisplay-Medium', '../../Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Medium', $weight: 500, $style: normal, $exts: woff2 woff)
// +font-face('MacPawFixelDisplay-SemiBold', '../../Fonts/MacPawFixelDisplay/MacPawFixelDisplay-SemiBold', $weight: 600, $style: normal, $exts: woff2 woff)
// +font-face('MacPawFixelDisplay-Bold', '../../Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Bold', $weight: 700, $style: normal, $exts: woff2 woff)
// +font-face('MacPawFixelDisplay-Black', '../../Fonts/MacPawFixelDisplay/MacPawFixelDisplay-Black', $weight: 900, $style: normal, $exts: woff2 woff)
