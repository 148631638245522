@import "../../../../common/variables"
@import "../../../../common/mixins"

.transaction__box-list
    min-width: 970px
    padding-top: 25px
    margin-bottom: 35px
    overflow: hidden
    +max-w(1030)
        min-width: unset
        width: 100%
        max-width: 100%
        overflow: scroll !important
    &::-webkit-scrollbar
        display: none

.transaction-list
    margin: 0
    padding: 0
    list-style: none
    display: flex
    justify-content: center
    min-width: 900px
    gap: 77px
    +max-w(1030)
        align-items: flex-end
    &__item
        padding-bottom: 25px
        +max-w(1030)
            text-align: center
    &__title
        text-shadow: 0px 0px 30.3px rgba(0, 0, 0, 0.25)
        font-size: 18px
        font-family: 'MacPawFixelDisplay-Regular'
        line-height: normal
        opacity: 0.5
        position: relative

    &__title::before
        position: absolute
        content: ''
        width: 150%
        height: 2px
        background-color: var(--grey-3)
        bottom: -13px
        left: 0

    &__item:nth-child(1) &__title:before
        width: 75%
        left: 45%
        +max-w(1030)
            width: 82%

    &__item:nth-child(2) &__title:before
        width: 255%
        left: -40%
        +max-w(1030)
            width: 265%

    &__item:nth-child(3) &__title:before
        width: 77%
        left: 27%

    &__item:nth-child(4) &__title:before
        width: 227%
        left: -92%
        +max-w(1030)
            width: 235%

    &__item:nth-child(5) &__title:before
        width: 100%
        left: -45%

    &__title::after
        position: absolute
        content: ''
        width: 15px
        height: 15px
        background-color: var(--white)
        border-radius: 50%
        border: 1px solid var(--grey-3)
        bottom: -19px
        left: 42%

.active
    position: relative

    &.transaction-list__title
        opacity: 1

    &.transaction-list__title::before
        background-color: var(--primary-purple)

    &.transaction-list__title::after
        background-image: url(/img/trans-aft.svg) !important
        width: 23px
        height: 23px
        bottom: -24px
        left: 43%
        border: none
