@import "../../../../common/variables"
@import "../../../../common/mixins"

.main-form-cards
    padding: 20px 0px !important
    width: 100%
    &_title1
        font-size: 20px
        font-weight: 600
    &_subtitle1
        font-size: 18px
        margin-top: 10px
    &_card
        background: white
        padding: 30px
        border-radius: 41px
        flex: 1
        &_title
            text-align: left
            font-weight: bold
            margin-top: 10px
    &_action
        display: flex
        align-items: center
        margin-top: 10px
        &>div:first-of-type
            font-size: 12px
            line-height: 18px
        &>div:last-of-type
            flex: 1
            text-align: right
        +max-w(1310)
            flex-direction: column
            align-items: flex-start
            &>div:last-of-type
                margin-top: 10px

    &_flex
        margin-top: 40px !important
        max-width: unset !important
        gap: 30px !important
        +max-w(700)
            flex-direction: column

.border
    border: 1px solid red
.fit-content
    flex: 0 !important
    max-width: fit-content
    margin: 0 auto
    min-width: 560px
    +max-w(700)
        min-width: auto
.flex-1
    flex: 1

.btn-go-back
    background: transparent
    border: 1px solid #3f48cc
    color: #3f48cc
    border-radius: 0
    padding-left: 20px
    padding-right: 20px
    position: relative
    font-size: 14px
    line-height: 40px
    font-family: 'ChakraPetch-SemiBold'
    display: flex
    gap: 10px
    align-items: center
    justify-content: center
    &::before
        content: ''
        width: 16px
        height: 16px
        background: url(/img/back-blue.svg) no-repeat right
    &>img
        filter: invert(1)

.gap-20
    gap: 20px

.gap-30
    gap: 30px

.white-form
    background: transparent
    width: 100%
    padding: 20px 0
.btn-disabled
    background: silver !important
    color: #909090 !important
    cursor: default !important

.physical_pin
    +max-w(534)
        flex-direction: column
.actions
    +max-w(534)
        flex-direction: column-reverse

.custom-autocomplete
  &__input
    height: 25px !important
    font-size: 14px
    color: black !important
    font-weight: 400
  &__value-container
    height: 38px !important
    padding-right: 0 !important
    padding-top: 0 !important
  &__control
    border-radius: 0 !important
    border: 1px solid #9ca3af !important
  &__indicator-separator
    width: 0 !important
  &__placeholder
    color: #9ca3af !important
    opacity: 1
    font-family: 'ChakraPetch-Regular'
    font-size: 12px
    line-height: 107%

  &__single-value
    font-size: 14px
    color: black !important
    font-weight: 400
  &__option
    font-family: 'ChakraPetch-SemiBold', Arial, Helvetica, sans-serif !important
    cursor: pointer !important
    font-size: 12px !important
    line-height: 16px !important
    & > div
        display: flex !important
    &:hover
      background-color: #2048E611 !important
    &--is-selected
      background-color: transparent !important
      color: #2048E6 !important

  &__menu
    box-shadow: 25px 25px 40px 0 rgba(16, 39, 83, 0.28) !important
    @apply border border-border-main