@import "../../common/variables"
@import "../../common/mixins"

*,
*::before,
*::after
  box-sizing: border-box

html
  font-family: sans-serif
  line-height: 1.15
  -ms-overflow-style: scrollbar
  -webkit-tap-highlight-color: rgba(#000, 0)

body
  font-family: $font-family-base
  font-size: $font-size-base
  font-weight: $font-weight-base
  line-height: $line-height-base
  text-align: left
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  // -webkit-font-smoothing: antialiased
  text-rendering: optimizelegibility
  backface-visibility: hidden
  -moz-osx-font-smoothing: grayscale

  &.modal-open
    overflow: hidden

// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@at-root
  @-ms-viewport
    width: device-width

body, h1, h2, h3, h4, h5, h6, p, ol, ul
  margin: 0
  padding: 0
  font-weight: normal

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section
  display: block

ol, ul
  list-style: none

img
  max-width: 100%
  height: auto
  display: block

a
  text-decoration: none
  color: inherit

a, button
  transition: all 0.3s ease

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.desktop-only
  +max-w($laptop_sm)
    display: none

.alert
  position: relative
  padding: 1rem 1rem
  margin-bottom: 1rem
  border: 1px solid transparent
  border-radius: 0.25rem

.alert-success
  color: #0f5132
  background-color: #d1e7dd
  border-color: #badbcc

.invalid-feedback
  display: block
  font-size: 12px !important
  margin-top: 0.25rem
  line-height: 18px
  font-family: 'ChakraPetch-Regular'
  color: #dc3545

.Order-Card-PIN-Input .invalid-feedback
  font-size: 12px !important
  display: block
  margin-top: 2px !important
  opacity: 0.8
  line-height: 18px
  font-family: 'ChakraPetch-Regular'
// .Layout_Wrapper
//   background: $color-background-second
.Layout_Wrapper + main
  background-color: var(--white-3)
.MainPage_Header
  .header
    padding-top: 56px
    background: var(--dark)
    background-size: initial
    +max-w(992)
      padding-top: 46px
.main-layout1
  background-color: var(--dark)

.main-layout0
  background-color: var(--white-3)
.main-layout1, .main-layout0
  display: flex
  flex-direction: column
  min-height: 100vh
  & > main
    flex: 1
