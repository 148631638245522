@import "../../common/variables"
@import "../../common/mixins"

.header
  padding: 63px 0 15px
  background-color: var(--white-3)
  font-family: "MacPawFixelDisplay-Light"
  +max-w(768)
    padding: 24px 0 15px

.onlyLogo--header
  padding: 55px 0 15px
  +max-w(768)
    padding: 32px 0 15px

.header__form
  max-width: 500px
  margin: 0 auto
  padding: 332px 0 0
  +max-w(992)
    padding-top: 176px

.logo
  line-height: 0
  & img
    min-width: 136px
    height: 43px

.nav
  display: flex
  align-items: center
  position: relative
  justify-content: space-between
  margin-bottom: 42px
  flex-wrap: nowrap
  +max-w(992)
    margin-bottom: 0
    flex-wrap: wrap

.onlyLogo
  justify-content: center

.nav-light
  margin-bottom: 0

.nav-toggler,
.nav-toggler__light
  display: none
  background: transparent
  border: none
  margin-left: auto
  padding: 0

.nav__content
  display: flex
  align-items: center
  width: 100%
  +max-w(992)
    &.active
      display: flex

.nav-light__content
  & .nav__link, .nav__auth-link
    font-family: "MacPawFixelDisplay-Medium" !important
  & .b-select-wrapper
    font-family: "MacPawFixelDisplay-Medium" !important
.nav__pages
  margin-left: 22px
  display: flex
  align-items: center
  flex: 1
  text-transform: capitalize
  +max-w(992)
    margin: 0 auto
    flex: 0
    display: block

.nav__link
  display: inline-flex
  margin-right: 63px
  color: var(--dark)
  +max-w(992)
    display: block
    margin: 0
    padding: 10px
    text-align: center

.nav-dark .nav__link
  color: var(--white-2)

.nav__link:last-child
  margin: 0

.nav__auth
  padding-top: 3px
  display: flex
  align-items: center
  +max-w(992)
    margin: 0 auto
    display: flex
    flex-direction: column-reverse
    gap: 30px
    margin-top: 10px
  & .b-select-wrapper
    font-family: "MacPawFixelDisplay-Light"
  & .dropdown
    margin-right: 16px
    & .b-select
      border: none
    & .dropdown-item
      +max-w(992)
        margin: 0 auto
  & span
    color: var(--secondary-purple)
  &-link:first-child
    color: var(--dark)
  &-link:last-child
    color: var(--secondary-purple)

.nav-dark .nav__auth-link:first-child
  color: var(--white-2)

.nav-toggler,
.nav-toggler__light
  display: none
  background: transparent
  border: none
  margin-left: auto
  padding: 0

.nav-toggler span,
.nav-toggler__light span
  display: block
  height: 1px
  border-radius: 40px
  background-color: var(--white)
  margin-bottom: 7px
  width: 23px

.nav-toggler::before,
.nav-toggler__light::before
  content: ""
  display: block
  height: 1px
  border-radius: 40px
  width: 100%
  background-color: var(--white)
  margin-bottom: 7px

.nav-toggler__light::before
  background-color: var(--dark)

.nav-toggler::after,
.nav-toggler__light::after
  content: ""
  display: block
  height: 1px
  border-radius: 40px
  width: 100%
  background-color: var(--white)

.nav-toggler__light::after
  background-color: var(--dark)

.menu_burger
  display: none
  +max-w(992)
    display: block
    & svg
      stroke: white

.dark_burger svg
  stroke: black !important

@media (max-width: 992px)
  .nav__content,
  .nav-light__content
    position: absolute
    z-index: 2
    height: calc(100vh - 50px)
    top: 50px
    bottom: 0
    left: 0
    right: 0
    background-color: var(--dark)
    flex-direction: column
    align-items: start
    justify-content: start
    padding: 20px
    display: none
  .nav-light__content
    background-color: var(--white-3)
