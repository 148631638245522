@media (max-width: 992px) {
  .stack_container {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    width: 100%;
    gap: 40px !important;
  }
  .reversable {
    flex-direction: column-reverse !important;
  }
}

@media (max-width: 768px) {
  .stack_container {
    text-align: left;
    gap: 19px !important;
    margin-bottom: 19px !important;
  }
}
