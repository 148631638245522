@import "../../../../common/variables"
@import "../../../../common/mixins"

.Card-Page-Wrapper
    gap: 30px
    flex: 1
    height: "fit-content"

.view-hover_close
    cursor: pointer

.btn-view-hover
  cursor: pointer
