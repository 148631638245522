@import "../../common/variables"
@import "../../common/mixins"

.ReactModalPortal
  position: relative
  z-index: 9999

.Modal
  top: 200px
  left: 50%
  transform: translateX(-50%)
  position: absolute
  overflow: auto
  outline: none
  background: #FFFFFF
  box-shadow: 0px 4px 117px rgba(0, 0, 0, 0.03)
  border-radius: 41px
  padding: 60px 40px
  width: calc(100% - 32px)
  max-width: 600px

  +max-w($mobile_md)
    top: 96px
    padding: 60px 30px

  &--Large
    max-width: 825px
    top: 30px

.Modal__Overlay
  position: fixed
  inset: 0px
  background-color: rgba(36, 36, 36, 0.6)

.Modal__Close
  position: absolute
  cursor: pointer
  right: 30px
  top: 30px

.Modal__Title
  font-size: 24px
  line-height: 36px
  text-align: center
  color: #0D1118
  margin-bottom: 20px

.Modal__Description
  font-weight: 600
  font-size: 16px
  line-height: 24px
  text-align: center
  color: #777E8A
  margin-bottom: 20px

  a
    color: $color-primary

.Modal__Button
  display: flex
  justify-content: center

.CustomDialog
  padding: 30px
  padding-top: 30px
  &--title
    margin-bottom: 20px
    display: flex
    align-items: center
    font-family: 'ChakraPetch-SemiBold'
    justify-content: space-between
