@import "../../common/variables"
@import "../../common/mixins"

.Page_Wrapper
  background-color: $color-background-second
  color: $color-text-second
  padding-top: 46px
  &__Logo
    background-color: $color-background-second
    padding: 20px 0 0
    display: flex
    justify-content: center
.instruction-qr-code
  +max-w($mobile_lg)
    margin-block: 21px
    height: auto !important
.outer-tip
  font-size: 16px
  display: none
  color: red
  text-align: center
  +max-w($mobile_lg)
    display: block
.inner-tip
  font-size: 16px
  line-height: 20px
  font-family: 'ChakraPetch-Regular'
  +max-w($mobile_lg)
    display: none

.anchor-email
  color: #5ca1ff

.transaction-hash
  display: block
  max-width: 600px
  & .Form-InputBox
    flex: 1
  & .transaction-hash-form
    +max-w($mobile_lg)
      flex-direction: column
      & button
        width: 100%
.received-status
  & p
    margin: 20px 0 0
  & a
    font-weight: bold
.to_dashboard
  width: 220px !important
.note-tip
  display: block
  font-size: 16px
  color: red
  margin-top: 20px
.currency-table
  & .table-octagon_item
    position: relative
    &:hover
      &::after
        pointer-events: none
        position: absolute
        content: ""
        left: -20px
        height: 100%
        top: 0
        width: calc(100% + 40px)
        background-color: #1e1e1e08
.table-filter
  margin-left: 20px
  +max-w($mobile_lg)
    margin-left: 0
    width: 100%
    margin-top: 10px
  & span
    display: block
    margin-bottom: 10px
    font-weight: 400
    font-size: 14px
    line-height: 17px
    color: #9a9a9a

  & input
    display: flex
    justify-content: space-between
    overflow: hidden
    border: 1px solid #F0F0F0
    height: 40px
    background: #ffffff
    font-weight: 400
    font-size: 12px
    line-height: 13px
    color: #212944
    padding: 0 15px
    width: 100%
