.login_form_head {
  text-align: center;
  margin-bottom: 35px;
}

.login_wrapper {
  padding: 25px 0 80px;
}

@media (max-width: 768px) {
  .login_wrapper .paper {
    padding: 38px 39px;
    text-align: center;
  }
  .login_wrapper form {
    margin-bottom: 40px;
  }
}
