.text-subtitle {
  margin: 0;
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
}
@media (max-width: 768px) {
  .text-subtitle {
    font-size: 18px;
  }
}

.paper-title {
  font-size: 36px;
  font-family: "MacPawFixelDisplay-Bold";
  line-height: 1.5;
  margin: 0 0 2px;
}

@media (max-width: 768px) {
  .paper-title {
    font-size: 20px;
    margin: 0 0 6px;
  }
}

.login-title {
  font-size: 20px;
  margin: 0 0 6px;
}

.text_link {
  font-family: "MacPawFixelDisplay-Regular";
  font-size: inherit;
}

.paper-title a,
.text-subtitle a,
.text_link {
  color: var(--primary-purple);
}
