.register_form_head {
  text-align: center;
  margin-bottom: 35px;
}

.register_wrapper {
  padding: 25px 0 80px;
}
.register_wrapper .b-select-menu li {
  justify-content: flex-start !important;
}
@media (max-width: 768px) {
  .register_wrapper .paper {
    padding: 38px 39px;
    text-align: center;
  }
  .register_wrapper form {
    margin-bottom: 40px;
  }
}

.select_sex {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 53px;
  height: 66.5px;
}
.policy {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-bottom: 47px;
  padding: 4px 0 0 4px;
  text-align: left;
}

.policy a {
  color: var(--primary-purple);
}

.register_wrapper .ReactModal__Content {
  font-family: "MacPawFixelDisplay-SemiBold";
}
.register_wrapper .Modal__Description a {
  color: var(--primary-purple) !important;
}

.register__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 56px;
  row-gap: 38px;
  align-items: end;
  margin-bottom: 38px;
  text-align: left;
}

.register__grid > *:nth-child(1) {
  order: 1;
}

.register__grid > *:nth-child(2) {
  order: 2;
}
.register__grid > *:nth-child(3) {
  order: 3;
}
.register__grid > *:nth-child(4) {
  order: 4;
}
.register__grid > *:nth-child(5) {
  order: 5;
}
.register__grid > *:nth-child(6) {
  order: 6;
}
.register__grid > *:nth-child(7) {
  order: 7;
}
.register__grid > *:nth-child(8) {
  order: 8;
}
.register__grid > *:nth-child(9) {
  order: 9;
}
.register__grid > *:nth-child(10) {
  order: 10;
}
.register__grid > *:nth-child(11) {
  order: 11;
}

.register__grid > *:nth-child(12) {
  order: 12;
}
.register__grid > *:nth-child(13) {
  order: 13;
}
.register__grid > *:nth-child(14) {
  order: 14;
}

@media (max-width: 992px) {
  .register__grid {
    grid-template-columns: 1fr;
    text-align: left;
  }

  .register__grid > *:nth-child(1) {
    order: 1;
  }

  .register__grid > *:nth-child(2) {
    order: 7 !important;
  }
  .register__grid > *:nth-child(3) {
    order: 2 !important;
  }
  .register__grid > *:nth-child(4) {
    order: 8 !important;
  }
  .register__grid > *:nth-child(5) {
    order: 3 !important;
  }
  .register__grid > *:nth-child(6) {
    order: 9 !important;
  }
  .register__grid > *:nth-child(7) {
    order: 4 !important;
  }
  .register__grid > *:nth-child(8) {
    order: 10 !important;
  }
  .register__grid > *:nth-child(9) {
    order: 5;
  }
  .register__grid > *:nth-child(10) {
    order: 11 !important;
  }
  .register__grid > *:nth-child(11) {
    order: 6 !important;
  }
  .register__grid > *:nth-child(13) {
    order: 14 !important;
  }
  .register__grid > *:nth-child(14) {
    order: 13 !important;
  }
}
