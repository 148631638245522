@import "../../../../common/variables"
@import "../../../../common/mixins"

.card-list
    display: flex
    list-style: none
    margin: 0 0 42px
    padding: 0
    gap: 24px
    flex-wrap: wrap
    &__item-active
        border-radius: 11px
        box-shadow: 0 0 0 2px var(--primary-purple)
    &__item
        cursor: pointer
        & div
            display: flex
            justify-content: center
            align-items: center
            border-radius: 11px
            background-color: var(--white)
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.11)
            min-width: 68px
            height: 45px
            & img
                max-width: 68px
                margin: auto
