.payment {
  padding: 55px 0 123px;
  background: var(--dark) url(/img/payment_bg.png) center 116% no-repeat;
}
.light-paymentt {
  background: var(--white-3) url(/img/rates-payment-bg.png) center 116% no-repeat;
  background-blend-mode: multiply;
}
.light-paymentt .h2 {
  font-weight: 600;
  color: var(--dark);
}
.light-paymentt .h2 span {
  color: var(--dark) !important;
}
.payment .h2 > span {
  font-weight: 600;
  color: white;
}
.payment .exchange__block-info {
  padding: 0 0 36px !important;
}
.payment .exchange__block-actions {
  padding: 0 5px;
}

.payment .exchange__block {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@media (max-width: 768px) {
  .payment {
    padding: 23px 0 46px;
    background: transparent url(/img/payment_bg.png) center 76% no-repeat;
  }

  .light-paymentt {
    background: var(--white-3) url(/img/rates-payment-bg.png) center 116% no-repeat;
    background-blend-mode: multiply;
  }
}

.h2-mob {
  display: none;
}

.payment__h2 {
  margin: 0 -85px 73px !important;
}

@media (max-width: 1360px) {
  .payment__h2 {
    margin: 0 0 73px !important;
  }
}

@media (max-width: 768px) {
  .payment__h2 {
    display: none;
  }
}

@media (max-width: 768px) {
  .h2-mob {
    display: block;
    font-size: 30px !important;
    margin: 0 0 35px !important;
  }
}

.payment__h2 span {
  display: block;
  text-align: right;
  color: white !important;
}

@media (max-width: 768px) {
  .payment__h2 span {
    display: none;
  }
}

@media (max-width: 768px) {
  .h2-mob span {
    display: block;
    text-align: right;
    padding-right: 8px;
  }
}

@media (max-width: 768px) {
  .h2-mob span:nth-child(2) {
    padding-right: 4px;
  }
}

.payment__form {
  max-width: 500px;
  margin: 0 auto;
  margin-top: -31px;
}
