.cards {
  padding: 32px 0 68px;
}

@media (max-width: 768px) {
  .cards {
    padding: 46px 0;
  }
}

.cards__h2 {
  margin-bottom: 30px !important;
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 96px;
  font-style: normal;
  line-height: 115%;
  text-transform: uppercase;
  margin: 0;
}

@media (max-width: 992px) {
  .cards__h2 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .cards__h2 {
    font-size: 30px !important;
    margin-bottom: 25px;
  }
}

.cards__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

@media (max-width: 992px) {
  .cards__row {
    display: block;
    margin-bottom: 30px;
  }
}

.card {
  border-radius: 20px;
  background: linear-gradient(#000, #000) padding-box,
    linear-gradient(70deg, rgba(217, 217, 217, 0), #d9d9d9, rgba(217, 217, 217, 0)) border-box;
  border: 1px solid transparent;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .card {
    max-width: 500px;
    margin: 0 auto 30px;
  }
}

.card__text {
  padding: 0 40px 28px;
  margin-top: -270px;
  min-height: 290px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .card__text {
    padding: 0 15px 22px;
    margin-top: -221px;
  }
}

.card__title {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 40px;
  text-transform: uppercase;
  margin: 0 0 11px;
  color: white;
  line-height: 1.15;
}

@media (max-width: 768px) {
  .card__title {
    font-size: 20px;
  }
}

.card__describe {
  font-size: 22px;
  line-height: normal;
  margin: 0 0 28px;
  font-family: "MacPawFixelDisplay-Light";
  color: white;
}

@media (max-width: 768px) {
  .card__describe {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
