.buy {
  padding: 88px 0 104px;
  font-family: "MacPawFixelDisplay-Light";
}

@media (max-width: 768px) {
  .buy {
    padding: 61px 0 28px;
  }
}

.buy__h2 {
  margin-bottom: 66px !important;
}

@media (max-width: 768px) {
  .buy__h2 {
    font-size: 30px;
    text-align: center;
  }
}

.buy__row {
  background: url(/img/bg-phone.png) 58% 41% no-repeat;
  background-size: initial;
}

@media (max-width: 1224px) {
  .buy__row {
    background-image: none;
  }
}

.buy__text {
  max-width: 672px;
}

@media (max-width: 1224px) {
  .buy__text {
    max-width: 100%;
  }
}

.buy__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.buy__list li {
  display: flex;
  align-items: center;
  margin-bottom: 46px;
}

@media (max-width: 768px) {
  .buy__list li {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 46px;
  }
}

@media (max-width: 768px) {
  .buy__list li:nth-child(2) {
    align-items: flex-end;
    margin-bottom: 44px;
  }
}

@media (max-width: 768px) {
  .buy__list-box--two {
    text-align: right;
    padding-left: 6px;
  }
}

.buy__list img {
  margin-right: 30px;
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .buy__list img {
    margin: 0 0 21px 0;
    width: 136px;
    transform: translateY(-1px);
  }
}

.buy__list-title {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 22px;
  text-transform: uppercase;
  line-height: normal;
  margin: 0 0 9px;
}

@media (max-width: 768px) {
  .buy__list-title {
    font-size: 20px;
  }
}

.buy__list-describe {
  font-size: 22px;
  line-height: normal;
  margin: 0;
}

@media (max-width: 768px) {
  .buy__list-describe {
    font-size: 18px;
  }
}
