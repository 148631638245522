@import "../../../common/variables"
@import "../../../common/mixins"

.Transactions__Table
  overflow-x: auto
  font-size: 12px
  line-height: 14px

  &::-webkit-scrollbar
    height: 3px

  &::-webkit-scrollbar-thumb
    border-radius: 2px
    background-color: $color-second

  .Table
    min-width: 950px
    padding-bottom: 30px

  .Table__Cell--Grey
    height: 68px
    padding: 4px 20px 10px 30px

    &:nth-child(3)
      padding-left: 27px
      padding-right: 0

    &:nth-child(5)
      padding-top: 16px
      padding-left: 20px

  .Transactions__Table-Head .Table__Cell
    padding: 1px 10px 10px 30px

.Status
  &--Error
    color: #EF382A

  &--Success
    color: #0BDE20

  &--Pending
    color: #5CA1FF
