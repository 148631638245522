@import "../../../../common/variables"
@import "../../../../common/mixins"


.card_details_header
  display: flex
  align-items: center
  flex-wrap: wrap
  & span
    white-space: nowrap
.card_status_label
  font-size: 14px
  text-transform: capitalize
  display: block
  padding: 4px 8px
  border-radius: 8px
  min-width: 80px
  text-align: center
  margin-left: 12px
  &.active
    background-color: #36c640
    color: white
  &.locked
    background-color: var(--bs-red)
    color: white
  &.not_active
    background-color: var(--bs-orange)
    color: white
  &.top-up
    background: #5ca1ff
    padding: 4px 16px
    margin-left: 6px
    color: white
    cursor: pointer

.card_details_balance
  gap: 8px
  font-family: "ChakraPetch-SemiBold"
  font-weight: 600
  font-size: 18px
  line-height: 24px
.card_balance
  display: flex
  align-items: center

.card-name
  font-family: 'ChakraPetch-Regular'
  margin-bottom: 16px