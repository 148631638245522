@import "../../common/variables"
@import "../../common/mixins"

.faq
    & header
        padding: 66px 0 45px
        +max-w($mobile_lg)
            padding: 24px 0 45px
    & +main
        background-color: var(--white-3)
        padding-bottom: 70px
.faq-list, .faq__view
    padding-bottom: 70px
.faq__view-content
    font-size: 20px
    +max-w($mobile_lg)
        font-size: 16px !important
.faq__content
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 30px 47px
    +max-w(940)
        grid-template-columns: 1fr

.faq__list-paper
    display: flex
    flex-direction: column
    color: var(--dark)
    background-color: var(--white)
    box-shadow: 0 0 25px 27px rgba(171, 170, 172, 0.1)
    border-radius: 20px
    text-align: left
    padding: 40px 54px
    gap: 40px
    font-family: "MacPawFixelDisplay-Light"
    margin-bottom: 20px
    +max-w($mobile_lg)
        padding: 20px 30px
    & strong
        font-family: "MacPawFixelDisplay-Bold"
        &:not(.inline_strong)
            display: block
        &:has(+ table)
            margin-bottom: 7px
    & table
        width: 100%
        font-size: inherit
        line-height: normal
        border-collapse: collapse
        & th, td
            border: 1px solid rgb(160, 160, 160)
            padding: 5px
            width: 50%

    &:not(.view-paper)
        cursor: pointer
    &:last-of-type
        margin-bottom: 0
    & a, .link
        color: var(--primary-purple) !important
    & .faq-title
        padding-bottom: 6px
        font-size: 22px
    & .lined_list
        margin: 0px 0 0 9px
        padding: 0 0 0 25px
        display: flex
        flex-direction: column
        gap: 14px
        list-style: none
        border-left: 5px solid var(--dark)
        font-size: inherit
        line-height: normal
        margin-block: 21px
    & ol:not(.lined_list), ul:not(.lined_list)
        display: flex
        list-style: none
        flex-direction: column
        gap: 23px
        padding: 0
        margin: 0
        font-size: inherit
        color: var(--dark)
        line-height: normal
        margin-block: 21px
        & li
            position: relative
            padding-left: 40px !important
        & li::after
            position: absolute
            content: counter(list-item)
            left: 0
            top: 4px
            width: 23px
            height: 22px
            display: block
            display: inline-flex
            align-items: center
            justify-content: center
            background-color: var(--dark)
            color: var(--white)
            font-size: 16px
            border-radius: 50%
            min-width: 23px
    & p
        line-height: normal
        +min-w($mobile_lg)
            font-size: 20px

.faq-content__date
    font-size: 16px
    color: var(--dark-3)
    text-transform: uppercase
.small-date
    font-family: 'MacPawFixelDisplay-Medium'
    font-size: 12px
    line-height: normal
.faq-bottom
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: -4px
    padding-top: 38px
    border-top: 1px solid var(--dark-3)
    border-top-color: rgba(0, 0, 0, 0.3)
    gap: 20px
    +max-w($mobile_lg)
        padding-top: 21px
        flex-direction: column
        color: var(--primary-purple)
    &__text
        font-family: 'MacPawFixelDisplay-SemiBold'
        color: var(--primary-purple)
        font-size: 22px
        line-height: normal
        +max-w($mobile_lg)
            font-size: 16px
    &__icons
        display: flex
        gap: 40px
        +max-w($mobile_lg)
            gap: 20px
        & svg:hover path
            fill: #5405d6
            fill-opacity: 1

.FAQ_TABLE_WRAPPER
    margin-block: 21px
