@import "../../common/variables"
@import "../../common/mixins"

.left_section
    margin-right: 50px
    max-width: 570px
    min-width: 570px
    position: relative
    +max-w(992)
        max-width: unset
        margin-right: 0px
    +max-w(647)
        min-width: auto

.transaction__title
    font-size: 22px
    font-family: 'MacPawFixelDisplay-SemiBold'
    line-height: normal
    margin: 0 0 48px
    +max-w(992)
        text-align: center
    +max-w(768)
        font-size: 18px
        margin: 0 0 37px
.transaction-form
    position: relative
    display: flex
    padding: 35px 50px 55px
    overflow: hidden
    &::after
        position: absolute
        content: url(/img/trans-after.svg)
        right: 0
        bottom: 0
    +max-w(1124)
        padding: 35px 24px 55px
        overflow: hidden
    +max-w(992)
        flex-direction: column
        overflow: hidden
        & form
            max-width: 100%
            margin-bottom: 40px
            padding-right: 0
    +max-w(768)
        padding: 35px 24px 40px
.policy
    display: flex
    flex-direction: column
    gap: 9px
    margin-bottom: 47px
    padding: 4px 0 0 4px
    text-align: left
    & a
        display: inline
        color: var(--primary-purple)

.exchange__actions
    display: flex
    gap: 15px
    flex-wrap: wrap
    margin-top: 49px
    +max-w(435)
        &>*
            max-width: unset
            min-width: 100% !important
.personal__box-title
    & p
        text-shadow: 0px 0px 30.3px rgba(0, 0, 0, 0.25)
        font-size: 18px
        font-family: 'MacPawFixelDisplay-Regular'
        margin-bottom: 35px
        +max-w(992)
            text-align: center
    & h4
        margin-bottom: 3px !important

.status-check__wrap
    width: 100%
    display: flex
    justify-content: center
    margin: 0 0 40px -50px
    padding-top: 19px

@media (max-width: 992px)
    .status-check__wrap
        margin: 0 0 38px
        padding-top: 0

.status-check__inner
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 46px
    max-width: 320px
    margin: 0 auto

.status-check__title
    font-size: 22px
    line-height: normal
    font-family: 'MacPawFixelDisplay-SemiBold'
    margin: 0

@media (max-width: 768px)
    .status-check__title
        font-size: 20px

.status-check__text
    font-size: 22px
    line-height: normal
    font-family: 'MacPawFixelDisplay-Medium'

.status-check__btn
    min-width: 204px

.status-check__bottom
    margin-top: 152px

@media (max-width: 768px)
    .status-check__bottom
        margin-top: 5px

.personal_info .select_sex
    justify-content: center
    margin-bottom: 30px
.personal_info .b-select-menu li
    justify-content: flex-start !important

.details span[data-for="how-calculated"]
    color: var(--primary-purple)
    text-decoration: underline
    cursor: pointer
.details .TooltipSimple
    font-family: "MacPawFixelDisplay-Regular"
    border-radius: 10px
    padding: 20px 21px
    max-width: 600px
    background: var(--wizarbit-tooltip-background)
    box-shadow: 2px 3px 10px 0 #5405D620
    border: 1px solid #DECEFB !important
    color: #5405D6
    +max-w(768)
        left: 16px !important
        right: 16px !important

    &::after
        background-color: var(--wizarbit-tooltip-background) !important
        border: 1px solid #DECEFB !important
    & p
        font-size: 18px
