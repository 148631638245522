@import "../../common/variables"
@import "../../common/mixins"

.PaymentCard
  background-color: $color-third
  color: #fff
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.46)
  border-radius: 41px
  padding: 40px
  width: 100%
  height: 100%
  position: relative
  overflow: hidden
  min-height: 605px
  display: flex
  flex-direction: column

  +max-w($mobile_md)
    padding: 30px
    min-height: 694px

.PaymentCard__Title
  text-transform: uppercase
  font-weight: 600
  margin-bottom: 40px

  +max-w($mobile_md)
    text-align: center

.PaymentCard__Exchange
  display: flex
  justify-content: space-between
  width: 100%
  align-items: center
  margin-bottom: 34px
  max-width: 350px

  +max-w($mobile_md)
    flex-direction: column

    svg
      display: block
      margin: 15px 0

.PaymentCard__Calc
  font-weight: 300
  font-size: 14px
  line-height: 22px
  padding-bottom: 32px
  position: relative
  margin-bottom: 34px

  a, span
    font-weight: 600
    cursor: pointer

  &::after
    content: ''
    height: 1px
    width: 255px
    border-bottom: 1px solid rgba(#fff, .3)
    position: absolute
    bottom: 0
    left: 0

  +max-w($mobile_md)
    text-align: center

    &::after
      width: 100%

.PaymentCard__Info
  font-size: 14px
  line-height: 17px

.PaymentCard__InfoItem
  display: flex
  margin-bottom: 15px

.PaymentCard__InfoText, .PaymentCard__InfoValue
  text-transform: uppercase

.PaymentCard__InfoText
  min-width: 170px
  font-weight: 600

.PaymentCard__InfoValue
  font-weight: 400
  word-break: break-all

.PaymentCard__Planet
  position: absolute
  bottom: 0
  right: 0

.PaymentCard__Bottom
  display: flex
  font-weight: 600
  font-size: 14px
  align-items: center
  line-height: 17px
  color: $color-primary
  margin-top: auto

  svg
    margin-right: 10px
    margin-top: -2px
