@import "../../../../common/variables"
@import "../../../../common/mixins"

.document__upload
    padding: 19px 10px 10px 18px
    display: flex
    flex-direction: column
    justify-content: space-between
    border-radius: 20px
    border: 1px solid rgba(0, 0, 0, 0.15)
    background: #F5F5F5
    width: 100%
    font-size: 22px
    line-height: normal
    font-family: 'MacPawFixelDisplay-Regular'
    & .upload_hint
        display: block
        margin-bottom: 49px
    & img
        width: 100px
        height: 100px
        object-fit: cover
        margin-bottom: 20px
    +max-w(992)
        max-width: 100%
    +max-w(768)
        font-size: 20px
        gap: 0
        padding: 19px 10px 10px 13px
        text-align: left
    & input[type="file"]
        width: 1px
        height: 1px
        overflow: hidden
        clip: rect(0 0 0 0)
        position: absolute
        padding: 0
        background-color: transparent
        border: none
    &-span
        font-size: 16px
        line-height: normal
        font-family: 'MacPawFixelDisplay-Regular'
        & span:nth-child(odd)
            opacity: 0.3 !important
        & span:nth-child(2)
            color: var(--primary-purple)

.upload__bottom
    display: flex
    align-items: center
    justify-content: space-between
