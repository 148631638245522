@import "../../../../common/variables"
@import "../../../../common/mixins"

.TablePagination
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 35px

  +max-w($mobile_lg)
    flex-direction: column

.TablePagination__Count
  color: #777e8a
  font-size: 12px
  line-height: 18px
  font-family: "ChakraPetch-Regular"

  +max-w($mobile_lg)
    margin-bottom: 30px

.TablePagination__Item
  background: transparent
  border: 1px solid #9ca3af
  padding: 7px 11px 7px
  color: $color-background
  font-size: 12px
  line-height: 14px
  text-align: center
  min-width: 30px
  cursor: pointer
  display: flex
  align-items: center

  &:not(:last-child)
    border-color: transparent
    margin-right: 19px
  
  &:nth-child(4) 
    padding: 7px 10px 7px
  
  &:last-child
    padding: 7px 14px 7px 15px

  &:hover
    border-color: #3f48cc

  &--Active
    background-color: #3f48cc
    border-color: #3f48cc
    border-radius: 0
    color: #fff
    cursor: default

  svg
    margin-left: 10px

.TablePagination__Controls
  display: flex

