@import "../../common/variables"
@import "../../common/mixins"

.Home
  background-color: $color-background
  color: $color-text-base
  overflow: hidden
  .h2
    font-family: "MacPawFixelDisplay-Bold"
    font-size: 96px
    font-style: normal
    line-height: 115%
    text-transform: uppercase
    margin: 0
    +max-w(768)
      font-size: 30px !important
      text-align: center
