@import "../../common/variables"
@import "../../common/mixins"

.PageContent
  font-size: 16px
  line-height: 150%

  a
    color: #5ba1ff

  h3
    font-size: 16px
    line-height: 19px
    margin-bottom: 15px

  .level
    margin-bottom: 15px

    span
      color: $color-primary

  ol
    list-style-type: decimal
    counter-reset: item
    //padding-left: 18px

    li
      margin-bottom: 15px
      display: block

      b
        display: inline-block
        margin-bottom: 15px
      //
      //&::before
      //  content: counters(item, ".") "."
      //  counter-increment: item
      //  margin-right: 5px

      & > ul > li
        margin-top: 15px
        margin-left: 40px

  ul
    li
      display: block
      position: relative
      margin-left: 20px
      margin-bottom: 15px

      &::before
        content: ''
        background-color: $color-second
        position: absolute
        left: -20px
        top: 6px
        width: 10px
        height: 10px
        border-radius: 50%

  p
    margin-bottom: 15px

  .mb-0
    margin-bottom: 0

