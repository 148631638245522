.form-input {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  position: relative;
}

.form-input span {
  font-family: "MacPawFixelDisplay-Light";
  font-size: 18px;
  opacity: 0.9;
  line-height: normal;
  margin-bottom: 6px;
  color: var(--dark);
  margin-left: 31px;
}
.form-input__dark span {
  color: var(--white);
  opacity: 0.6;
}
.form-input .error-label {
  opacity: unset;
  color: var(--error);
}
.form-input .error-label:last-of-type {
  font-size: 16px;
  position: absolute;
  bottom: 0;
  transform: translateY(130%);
  opacity: 0.8;
}
.form-input input,
.form-input textarea {
  font-family: "MacPawFixelDisplay-Regular";
  font-size: 18px;
  line-height: normal;
  color: var(--dark);
  padding: 17px 31px;
  border-radius: 56px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: var(--white-3);
  backdrop-filter: blur(20px);
  min-height: 66.5px;
  box-sizing: border-box;
  width: 100%;
}
.form-input textarea {
  border-radius: 35px;
  appearance: none;
  resize: none;
  padding: 33px;
}
.form-input__dark input {
  border: 1px solid rgba(255, 255, 255, 0.9);
  background-color: transparent;
  color: var(--white);
}
.form-input input[type="password"] {
  font: small-caption;
  font-size: 22px;
  letter-spacing: 5px;
}
.form-input .error_input {
  border: 1px solid var(--red, rgba(0, 0, 0, 0.15));
}
