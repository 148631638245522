.assets {
  padding: 19px 0 50px;
  background-image: url(/img/rates-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rates-main .h2 {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 96px;
  font-style: normal;
  line-height: 115%;
  text-transform: uppercase;
  margin: 0;
}
@media (max-width: 768px) {
  .assets {
    padding: 28px 0 23px;
    background-image: none;
  }
}

.assets__h2 {
  margin: 0 0 0;
}

@media (max-width: 992px) {
  .assets__h2 {
    font-size: 75px;
  }
}

@media (max-width: 768px) {
  .assets-form {
    padding: 0 !important;
    padding-top: 40px !important;
  }
  .assets__h2 {
    margin-bottom: 60px !important;
    text-align: center;
  }
}

.assets__h2-mob {
  display: none;
}

.rates-main .assets__info {
  display: flex;
  justify-content: space-between;
  margin-top: -82px;
  gap: 21px;
}

@media (max-width: 1200px) {
  .assets__info {
    margin-top: 0;
    flex-direction: column;
  }
}

.assets-form {
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 40px 0 0 31px;
}

@media (max-width: 1200px) {
  .assets-form {
    margin: 0 auto 20px;
  }
}

@media (max-width: 768px) {
  .assets-form {
    padding: 28px 0 0 24px;
    margin: 0 auto 15px;
  }
}

.exhange-field__eur select {
  font-size: 18px;
  width: 90px;
  min-width: 90px;
  padding: 0 17px;
  height: 100%;
  border: none;
  color: var(--dark);
  background-color: transparent;
  background-image: url(/img/select-arrow-dark.svg);
  background-position: 80% 75%;
  background-repeat: no-repeat;
  font-family: "MacPawFixelDisplay-Regular";
  cursor: pointer;
}

.exhange-field__eur select:focus {
  outline: none;
}

.assets__block {
  display: flex;
  gap: 21px;
}

@media (max-width: 992px) {
  .assets__block {
    flex-direction: column;
    gap: 0;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .rates-main .assets__block .assets__link-box {
    margin-bottom: 20px;
  }
}

.rates-main .assets__link-box {
  border-radius: 20px;
  padding: 18px 20px 18px 24px;
  margin-bottom: 32px;
  margin-top: -10px;
  min-width: 379px;
  box-shadow: 0 0 15px 10px rgba(25, 24, 24, 0.09);
}

@media (max-width: 768px) {
  .rates-main .assets__link-box {
    min-width: 339px;
  }
}

.assets__link_grow {
  background: var(--white) url(/img/graph-2.png) left bottom no-repeat;
}

.assets__link_down {
  background: var(--white) url(/img/graph-1.png) left bottom no-repeat;
}

.assets__link-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 73px;
}

.assets__link-cur {
  display: flex;
  align-items: center;
}

.assets__link-cur-info {
  margin-left: 15px;
}

.assets__link-cur-info span {
  display: block;
  color: var(--dark);
}

.assets__link-cur-info span:first-child {
  font-family: "MacPawFixelDisplay-Bold";
  font-style: normal;
  margin-bottom: 4px;
}

.assets__link-cur-info span:last-child {
  font-style: normal;
}

.assets__link-price {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.assets__link_grow .assets__link-price {
  color: var(--green);
}

.assets__link_down .assets__link-price {
  color: var(--red);
}

.assets__link {
  background-color: var(--dark);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 38px;
  height: 38px;
  float: right;
}

.assets__info-middle {
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .assets__info-middle {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .assets__info-middle {
    flex-direction: column;
  }
}

.rates-main .assets__info-middle .assets__link-box {
  margin-right: 20px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .rates-main .assets__info-middle .assets__link-box {
    margin-right: 0;
    margin-bottom: 18px;
  }
}

.rates-main .assets__info-middle .assets__link-box:last-child {
  margin-right: 0;
}
/*--------------------------------------------------------------
# end assets
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# min
--------------------------------------------------------------*/
.min {
  padding: 29px 0 88px;
}

@media (max-width: 768px) {
  .min {
    padding: 60px 0 81px;
  }
}

.min__title {
  margin: 0 0 40px;
}

@media (max-width: 768px) {
  .min__title {
    font-size: 30px !important;
    text-align: center;
    margin: 0 0 29px !important;
  }
}

.min__box {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (max-width: 768px) {
  .min__box {
    gap: 13px;
  }
}

.min-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
}

@media (max-width: 992px) {
  .min-list {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .min-list {
    flex-direction: column;
    margin: 0 -16px;
    gap: 8px;
  }
}

.min-list__item {
  border-radius: 20px;
  background-color: var(--white);
  box-shadow: 0px 4px 117px 0px rgba(0, 0, 0, 0.03);
  padding: 34px 25px 20px 25px;
  position: relative;
}

@media (max-width: 768px) {
  .min-list__item {
    padding: 34px 25px 30px 26px;
  }
}

.min-list__item:nth-child(1) {
  max-width: 370px;
  width: 100%;
}

@media (max-width: 768px) {
  .min-list__item:nth-child(1) {
    max-width: 100%;
  }
}

.min-list__item:nth-child(1) .min-list__text {
  max-width: 276px;
  width: 100%;
}

.min-list__item:nth-child(1)::before {
  position: absolute;
  content: url(/img/min-bg-bef.svg);
  top: 0;
  right: 0;
}

.min-list__item:nth-child(1)::after {
  position: absolute;
  content: url(/img/min-bg-aft.svg);
  top: 90px;
  right: 0;
}

.min-list__item:nth-child(2) {
  max-width: 270px;
  width: 100%;
}

@media (max-width: 768px) {
  .min-list__item:nth-child(2) {
    max-width: 100%;
  }
}

.min-list__item:nth-child(2) .min-list__text {
  max-width: 209px;
  width: 100%;
}

.min-list__item:nth-child(3) {
  max-width: 470px;
  width: 100%;
}

@media (max-width: 768px) {
  .min-list__item:nth-child(3) {
    max-width: 100%;
  }
}

.min-list__item:nth-child(3)::before {
  position: absolute;
  content: url(/img/min-bg-3.svg);
  top: 34px;
  right: 30px;
}

@media (max-width: 768px) {
  .min-list__item:nth-child(3)::before {
    right: 19px;
  }
}

.min-list__item:nth-child(3) .min-list__text {
  max-width: 338px;
  width: 100%;
}

.min-list .min-list__item-bot {
  max-width: 100%;
  background-image: url(/img/min-bg-4.png);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: initial;
  padding: 34px 20px 0 25px;
}

@media (max-width: 768px) {
  .min-list .min-list__item-bot {
    background-image: url(/img/min-bg-4-mob.png);
    background-size: cover;
  }
}

.min-list .min-list__item-bot::before,
.min-list .min-list__item-bot::after {
  display: none;
}

.min-list__item--bottom .min-list__text {
  max-width: 276px;
  width: 100%;
}

.min-list__box-img {
  margin-bottom: 30px;
}

.min-list__title {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-ExtraBold";
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 8px;
  max-width: 160px;
  width: 100%;
}

@media (max-width: 768px) {
  .min-list__title {
    font-size: 20px;
  }
}

.min-list__text {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
  margin: 0;
}

@media (max-width: 768px) {
  .min-list__text {
    font-size: 18px;
  }
}

.min-list__text-bot {
  margin: 0 0 82px;
}

@media (max-width: 768px) {
  .min-list__text-bot {
    margin: 0 0 35px;
  }
}

.min-list__box-marg {
  margin-bottom: 58px;
}
/*--------------------------------------------------------------
# end min
--------------------------------------------------------------*/

@media (max-width: 768px) {
  .payment {
    padding: 23px 0 54px;
    background-position: center 76%;
  }
}

.h2-mob {
  display: none;
}

.payment__h2 {
  margin: 0 -85px 73px;
}

@media (max-width: 1360px) {
  .payment__h2 {
    margin: 0 0 73px;
  }
}

@media (max-width: 768px) {
  .payment__h2 {
    display: none;
  }
}

@media (max-width: 768px) {
  .h2-mob {
    display: block;
    font-size: 40px;
    margin: 0 0 43px;
  }
}

.payment__h2 span {
  display: block;
  text-align: right;
}

@media (max-width: 768px) {
  .payment__h2 span {
    display: none;
  }
}

@media (max-width: 768px) {
  .h2-mob span {
    display: block;
    text-align: right;
    padding-right: 8px;
  }
}

@media (max-width: 768px) {
  .h2-mob span:nth-child(2) {
    padding-right: 4px;
  }
}

.payment__form {
  max-width: 500px;
  margin: 0 auto;
  margin-top: -31px;
}

.exhange-field {
  border-radius: 56px;
  border: 1px solid #000;
  background: var(--Gray, rgba(255, 255, 255, 0.02));
  backdrop-filter: blur(20px);
}

.exhange-field input {
  color: var(--dark);
}

.exhange-field input::placeholder {
  color: var(--dark);
}

.exhange-field select {
  background-image: url(/img/select-arrow-dark.svg);
  color: var(--dark);
  font-family: "MacPawFixelDisplay-ExtraBold";
}

.exhange-field input:focus,
.exhange-field select:focus {
  outline: none;
}

.payment__form-info {
  color: var(--dark);
  font-family: "MacPawFixelDisplay-Regular";
}

@media (max-width: 768px) {
  .header__form-btns {
    gap: 35px;
  }
}
/*--------------------------------------------------------------
# end payment
--------------------------------------------------------------*/
