@import "../../../common/variables"
@import "../../../common/mixins"

.password__recovery
  font-size: 18px
  padding: 25px 0 80px
  & .stack_container
    margin-block: 0 !important
  & form
    display: flex
    +max-w(768)
      flex-direction: column
  .alert-success
    border-radius: 20px
    font-family: 'MacPawFixelDisplay-SemiBold'
.password__recovery .text-center
  padding-bottom: 35px

.recovery__control
  max-width: 670px
  width: 100%
  margin-right: 50px
  +max-w(768)
    text-align: center
    margin-right: 0
    max-width: auto
    margin-bottom: 20px
  label
    margin-bottom: 55px
    max-width: 507px
    +max-w(768)
      margin-bottom: 40px
      max-width: inherit

.password__recovery .paper
  position: relative
  overflow: hidden
  padding: 24px 50px 35px
  +max-w(768)
    padding: 28px 40px

.password__recovery .paper::after
  position: absolute
  content: url(/img/fag-after.svg)
  right: 0
  bottom: -73px

.recovery__contacts
  display: flex
  flex-direction: column
  gap: 33px
  padding-left: 55px
  max-width: 450px
  width: 100%
  position: relative
  font-size: 22px
  +max-w(768)
    padding-left: 0
    padding-top: 37px
    max-width: 100%
    gap: 17px
    font-size: 18px
    text-align: center
  &::before
    position: absolute
    content: ''
    background-color: var(--grey-3)
    width: 1px
    height: 300%
    left: 0
    top: -24px
    +max-w(768)
      top: 0
      left: -40px
      width: 160%
      height: 1px
  .password__title
    font-family: 'MacPawFixelDisplay-SemiBold'
    line-height: normal
    margin: 0
  .password-list
    font-family: 'MacPawFixelDisplay-Regular'
  .password-list__item
    display: flex
    align-items: center
    gap: 12px
    +max-w(768)
      justify-content: center
    &:first-of-type
      margin-bottom: 19px
    & img
      height: 20px
  .password__email
    margin-top: 7px
    display: flex
    flex-direction: column
    gap: 11px
    & span
      font-family: "MacPawFixelDisplay-Light"
      font-size: 18px
    & a
      font-size: 24px
      font-family: 'MacPawFixelDisplay-Regular'
      color: var(--primary-purple)
      text-decoration: underline
      z-index: 1
