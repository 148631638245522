html {
  background-color: var(--white);
  color: var(--dark);
  font-family: "MacPawFixelDisplay-Light";
  font-size: 18px;
}

/* header */
.header {
  padding: 56px 0 22px;
  background-color: var(--white-3);
}

@media (max-width: 768px) {
  .header {
    padding: 50px 0 15px 0;
  }
}
/*end header */

.contact {
  padding: 25px 0 51px;
  background-color: var(--white-3);
}

@media (max-width: 768px) {
  .contact {
    padding: 16px 0 30px;
  }
}

.contact__box-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.contact-title {
  font-size: 96px;
  font-family: "MacPawFixelDisplay-ExtraBold";
  line-height: 1.15;
  margin: 0;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 40px;
    margin: 0;
  }
}

.contact-subtitle {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-ExtraBold";
  line-height: normal;
  margin: 0 0 30px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .contact-subtitle {
    font-size: 20px;
  }
}

.contact__content {
  max-width: 100%;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 4px 117px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .contact__content {
    flex-direction: column;
    gap: 40px;
  }
}

.contact-form {
  padding: 55px 50px 55px;
  background-color: var(--white);
  border-radius: 20px;
  text-align: left;
  width: 60%;
}

@media (max-width: 768px) {
  .contact-form {
    padding: 38px 24px;
    text-align: center;
    width: 100%;
  }
}

.contact-form__box-label {
  display: block;
  margin-bottom: 37px;
}

@media (max-width: 768px) {
  .contact-form__box-label {
    text-align: left;
    gap: 19px;
    margin-bottom: 35px;
  }
}

.contact-form__label {
  display: flex;
  flex-direction: column;
  max-width: 570px;
  width: 100%;
}

@media (max-width: 768px) {
  .contact-form__label {
    max-width: 100%;
  }
}

.contact-form__label span {
  font-family: "MacPawFixelDisplay-Light";
  font-size: 18px;
  opacity: 0.5;
  line-height: normal;
  color: var(--dark);
  margin-bottom: 6px;
}

.contact-form__label input {
  font-family: "MacPawFixelDisplay-Light";
  font-size: 22px;
  line-height: normal;
  color: var(--dark);
  padding: 17px 31px;
  border-radius: 56px;
  border: 1px solid var(--dark, rgba(0, 0, 0, 0.15));
  background-color: var(--white-3);
  backdrop-filter: blur(20px);
}

.contact-form__label input::placeholder {
  font-family: "MacPawFixelDisplay-Regular";
  font-size: 20px;
  line-height: normal;
  color: var(--dark);
}

.contact-form__label input:focus,
.contact-form__textarea:focus {
  outline: none;
}

.contact-form__textarea {
  font-family: "MacPawFixelDisplay-Light";
  font-size: 22px;
  line-height: normal;
  color: var(--dark);
  padding: 33px;
  border-radius: 35px;
  border: 1px solid var(--dark, rgba(0, 0, 0, 0.15));
  background: var(--white-3);
  backdrop-filter: blur(20px);
  resize: none;
  height: 270px;
}

.contact-form__link {
  display: block;
  margin-bottom: 54px;
  font-family: "MacPawFixelDisplay-Regular";
  font-size: 18px;
  line-height: normal;
  color: var(--primary-purple);
}

@media (max-width: 768px) {
  .contact-form__link {
    margin-bottom: 38px;
  }
}

.contact-form__btn {
  min-width: 198px;
}

.check__input {
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  position: absolute;
  padding: 0;
  background-color: transparent;
  border: none;
}

.check__box_purple {
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--primary-purple);
  background-color: transparent;
  top: 2px;
  left: -4px;
}

.check__input:checked + .check__box_purple {
  background-image: url(/img/checkbox.svg);
  border: none;
}

.check__input:focus + .check__box_purple {
  width: 23px;
  height: 23px;
}

.singup-register {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-bottom: 35px;
}

@media (max-width: 768px) {
  .singup-register {
    text-align: left;
    margin-bottom: 40px;
  }
}

.singup-register__label {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Regular";
  padding-left: 35px;
  line-height: normal;
  position: relative;
}

@media (max-width: 768px) {
  .singup-register__label {
    font-size: 18px;
    display: block;
  }
}

.singup-register__label a {
  color: var(--primary-purple);
}

@media (max-width: 768px) {
  .singup-register__label:nth-child(3) label a {
    display: block;
  }
}

.captcha {
  margin-bottom: 49px;
}

@media (max-width: 768px) {
  .captcha {
    margin-bottom: 40px;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-image: url(/img/contact-bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 500px;
  width: 100%;
  height: auto;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media (max-width: 768px) {
  .contacts {
    border-radius: 20px;
    max-width: 100%;
  }
}

.contacts::before {
  position: absolute;
  content: url(/img/befor-cont.svg);
  right: 0;
  bottom: 0;
  z-index: 9;
}

.contacts__box {
  padding: 32px 55px;
  display: flex;
  flex-direction: column;
  gap: 33px;
}

@media (max-width: 768px) {
  .contacts__box {
    padding: 30px 24px;
    max-width: 100%;
  }
}

.contacts__img {
  max-width: 500px;
  width: 100%;
  display: flex;
  margin-top: auto;
  border-bottom-right-radius: 20px;
}

@media (max-width: 768px) {
  .contacts__img {
    max-width: 100%;
  }
}

.contacts__img img {
  border-bottom-right-radius: 20px;
  max-width: 100%;
}

@media (max-width: 768px) {
  .contacts__img img {
    max-width: 100%;
    border-bottom-left-radius: 20px;
  }
}

.contacts__title {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-SemiBold";
  color: var(--white);
  line-height: normal;
  margin: 0;
}

@media (max-width: 768px) {
  .contacts__title {
    font-size: 18px;
  }
}

.contacts-list {
  list-style: none;
  margin: 0 0 7px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 19px;
}

@media (max-width: 768px) {
  .contacts-list {
    margin: 0 0 11px;
    gap: 15px;
  }
}

.contacts-list__item img {
  margin-right: 9px;
}

.contacts-list__link {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-Light";
  color: var(--white);
}

@media (max-width: 768px) {
  .contacts-list__link {
    font-size: 18px;
  }
}

.contacts__email {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.contacts__email span {
  font-size: 18px;
  font-family: "MacPawFixelDisplay-Light";
  color: var(--white);
  opacity: 0.5;
}

.contacts__email a {
  font-size: 24px;
  font-family: "MacPawFixelDisplay-Light";
  color: var(--white);
  text-decoration: underline;
  z-index: 1;
}
.contact .footer__phone {
  font-family: "MacPawFixelDisplay-Light";
  justify-content: flex-start;
  font-size: 22px;
}
.contact label a {
  color: var(--primary-purple);
}
