@import "../../../../common/variables"
@import "../../../../common/mixins"


.faq-prices
    &__tab
        border-bottom: 2px solid #ccc
        margin: 40px 0 20px
        +max-w($mobile_lg)
            border: none
            border-left: 2px solid #ccc
        & .active::after
            position: absolute
            content: ""
            width: 200%
            height: 5px
            background-color: black
            bottom: -2px
            +max-w($mobile_lg)
                bottom: 0
                left: -16px
                width: 5px
                height: 100%
        &-item
            cursor: pointer
            position: relative
            display: inline-block
            padding: 6px 0 16px
            font-size: 22px !important
            &:first-child
                margin-right: 95px
                +max-w($mobile_lg)
                    margin-bottom: 14px
            +max-w($mobile_lg)
                display: block
                margin-left: 14px
                padding: 6px 0
    &__table
        &-item
            display: flex
            padding: 13px 0
            font-weight: 500
            border-top: 1px solid rgba(198, 198, 198, 0.60)
            &:first-child
                color: #ccc
                border-top: none
            & > div
                font-weight: bold !important
                &:first-child
                    flex: 1
                    +max-w($mobile_lg)
                        max-width: 60%
                        flex: none
                &:last-child
                    min-width: 240px
                    +max-w($mobile_lg)
                        min-width: auto
                        flex: 1
                        text-align: right
