@import "../../../common/variables"
@import "../../../common/mixins"

.three-steps
    max-width: calc(1170px + 160px)
    padding: 80px
    +max-w(460)
        padding: 40px
    margin: 40px auto 0
    border-radius: 40px
    &__header
        display: flex
        flex-direction: column
        align-items: center
        gap: 30px
        text-align: center
        +max-w($mobile_lg)
            gap: 15px
            flex-direction: column
            padding: 0 20px 0px
        & h1
            font-size: clamp(25px, 4vw, 40px)
            font-family: "MacPawFixelDisplay-Bold"
            line-height: 157%
            +max-w($tablet)
                min-width: 280px
            +max-w($mobile_lg)
                min-width: auto
                width: 100%
        & > div:last-of-type
            font-size: clamp(18px, 4vw, 24px)
            font-family: "MacPawFixelDisplay-Regular"
            line-height: normal
        & p
            +max-w($mobile_lg)
                display: inlin

.step-cards
    margin: 30px auto 0
    display: flex
    gap: 30px
    width: fit-content
    +max-w(460)
        width: unset
    +max-w($tablet)
        flex-direction: column
    &__item
        box-shadow: 0 0 15px 0 #6495ed22
        width: 370px
        max-height: 600px
        height: 600px
        padding: 45px 30px 0 35px
        background: white
        border-radius: 40px
        display: flex
        flex-direction: column
        gap: 15px
        +max-w($tablet)
            width: 100%
            border-radius: 20px
        +max-w($mobile_lg)
            height: 530px
        & button
            height: 55px
        & > span
            font-size: clamp(18px, 4vw, 24px)
            font-family: "MacPawFixelDisplay-Regular"
            line-height: normal
        & h1
            text-transform: uppercase
            font-size: 25px
            font-family: "MacPawFixelDisplay-Bold"
            & span
                display: inline-flex
                width: 32px
                height: 32px
                border-radius: 16px
                background-color: black
                color: white
                align-items: center
                justify-content: center

        &_1
            background: linear-gradient(111deg, #A167FF 1%, #5405D6 109.47%)
            position: relative
            color: white
            & .phone-register
                position: absolute
                bottom: 0
                left: 50%
                transform: translateX(-50%)
                width: 320px
                @media screen and (max-width: 968px)
                    width: 400px
                +max-w(500)
                    width: 300px
            & h1 > span
                background-color: white
                color: #995EFB
        &_2
            background: url(/img/just-cards.png) no-repeat, white
            background-size: 300px
            background-position: center 280px
            +max-w($tablet)
                background-position: center 220px
                background-size: 400px
            +max-w(500)
                background-size: 300px
.just-chips
    margin-top: 49px
    +max-w($mobile_lg)
        margin-top: 40px
    & > div
        display: flex
        justify-content: center
        gap: 21px
        margin-top: 18px
        min-height: 58px
        +max-w($mobile_lg)
            &:first-of-type
                margin-top: 0
        & > div
            display: flex
            align-items: center
            gap: 12px
            border-radius: 12px
            background-color: #EEEEEE
            padding: 13px 15px
            font-size: 22px
            font-family: "MacPawFixelDisplay-Bold"
.chip-fill
    background-color: #1a1b1c
    color: white
    padding: 2px 15px
    border-radius: 40px

.Toastify
    & > div
        top: 12px
        right: 12px
        +max-w(480)
            left: 12px
            width: auto
        gap: 12px
        display: flex
        align-items: flex-end
        flex-direction: column
