@import "../../common/variables"
@import "../../common/mixins"

.country-table
    & .table_head
        padding: 15px
        display: flex
        align-items: center
        gap: 4px
        font-size: 16px
        line-height: normal
        font-family: 'MacPawFixelDisplay-Regular'
    & .table_head
        color: #808080
        +max-w(768)
            display: none
    & .table_body
        font-size: 16px
        line-height: normal
        font-family: 'MacPawFixelDisplay-Regular'
        padding: 15px
        max-width: 1170px
        width: 100%
        border-radius: 20px
        background: rgba(255, 255, 255, 0.10)
        margin-bottom: 16px

        & .collapse-arrow
            width: 0
            display: none
            +max-w(768)
                width: auto
                display: block
        & .desktop-info
            display: flex
            align-items: center
            gap: 4px
        & .mobile-info
            transition: max-height 0.35s
            max-height: 0
            display: none
            overflow: hidden
            +max-w(768)
                display: block
    &__country
        width: 25%
        display: flex
        align-items: center
        gap: 10px
        +max-w(768)
            width: 100%
        & img
            display: inline-block
            width: 30px
            height: 30px
    &__buy
        width: 5%
        +max-w(768)
            display: none
    &__sell
        width: 15%
        +max-w(768)
            display: none
    &__methods
        width: 30%
        +max-w(768)
            display: none
    &__issuing
        flex: 1
        +max-w(768)
            display: none
.email_wrapper
    width: 500px
    +max-w(768)
        width: 100% !important
        margin: 0 auto
        & + button
            margin: 20px auto 0

.country_forma
    display: flex
    align-items: flex-end
    gap: 20px
    +max-w(768)
        margin-top: 40px
        flex-direction: column
.table_body .country-table__buy, .table_body .country-table__sell
    padding-left: 6px
