@import "../../../common/variables"
@import "../../../common/mixins"

// .paper
//   margin-bottom: 70px
.custom-select
  width: 100%
  & .error-label
    color: var(--error)
  & > span
    font-family: "MacPawFixelDisplay-Light"
    margin-left: 31px
  &__normal
    margin-top: 6px
    border-radius: 56px
    height: 67px
    line-height: 67px
    display: flex
    align-items: center
    background: linear-gradient(#000, #000) padding-box, linear-gradient(to right, var(--primary-purple), #d9d9d9) border-box
    border: 1px solid transparent
  &__error
    border: 1px solid var(--error) !important
  &__light
    border: 1px solid rgba(0,0,0,0.25)
    background: var(--white-3) !important
    & input
      color: var(--dark) !important
      &::placeholder
        color: var(--dark) !important
        opacity: 0.6
    & select
      color: var(--dark) !important
      background-image: url(/img/select-arrow-dark.svg) !important

.custom-select input
  font-family: "MacPawFixelDisplay-Regular"
  font-size: 18px
  margin-right: 6px
  width: 100%
  border-top-left-radius: 56px
  border-bottom-left-radius: 56px
  border: none
  background: transparent
  height: 100%
  padding: 0 0 0 32px
  color: var(--white)
  &::placeholder
    color: white
    opacity: 0.4

.custom-select select
  appearance: none
  font-size: 18px
  width: 230px
  min-width: 145px
  padding: 0 31px 0 0
  border: none
  border-top-right-radius: 56px
  border-bottom-right-radius: 56px
  height: 100%
  background-color: transparent
  background-image: url(/img/select-arrow.svg)
  background-position: 90% 75%
  background-repeat: no-repeat
  color: var(--white)
  font-family: "MacPawFixelDisplay-Regular"
  line-height: 1 !important
  text-align: right

.form-select__custom
  position: relative
  display: flex
  flex-direction: column
  width: 100%
  font-family: "MacPawFixelDisplay-Light"
  & .error-label
    color: var(--error)
    opacity: 1
  & .error
    border: 1px solid var(--error)

.form-select__custom span
  font-size: 18px
  opacity: 0.7
  line-height: normal
  color: var(--dark)
  margin-bottom: 6px
  margin-left: 31px

.form-select__custom input
  font-size: 22px
  line-height: normal
  color: var(--dark)
  border-radius: 56px
  border: 1px solid var(--dark, rgba(0, 0, 0, 0.15))
  background-color: var(--white-3)
  backdrop-filter: blur(20px)
  box-sizing: border-box

/* .form-select__custom select 
 position: absolute
 font-size: 22px
 width: 100%
 border: none
 height: 100%
 background-color: transparent
 background-image: url(/img/select-arrow-dark.svg)
 background-position: 90% 63%
 background-repeat: no-repeat
 color: var(--dark)
 font-family: "MacPawFixelDisplay-Bold"
 top: 19px
 left: 31px
 appearance: none
 -webkit-appearance: none
 */
.border_wrapper
  border-radius: 56px
  border: 1px solid rgba(0,0,0,0.25)
.form-select__custom
  .SelectSingle
    width: 100%
    &__single-value
      color: var(--dark)
      font-size: 18px !important
      font-family: 'MacPawFixelDisplay-Regular'
      overflow: visible !important
    & .SelectSingle__control
      background-color: var(--white-3) !important
      border: none !important
      outline: none !important
      height: 66.5px !important
      border-radius: 56px !important
      &--is-focused
        box-shadow: none !important
    & .SelectSingle__value-container
      padding: 17px 31px
    & .SelectSingle__indicators
      align-items: flex-end
      padding-bottom: 8px
      margin-right: 5px
    & .SelectSingle__placeholder
      font-family: "MacPawFixelDisplay-Light"
      font-size: 18px
    &__input
      background: transparent !important
      border: 1px solid red
      backdrop-filter: none !important
    &__option--is-selected
      background: var(--primary-purple) !important
    &__option--is-focused:not(.SelectSingle__option--is-selected)
      background: var(--secondary-purple-3) !important
    .SelectSingle__Label
      display: inline-block
      font-size: 14px
      color: $color-seventh
      font-weight: 400
      margin-bottom: 10px

    .SelectSingle--disabled
      background-color: #e9ecef
      & .SelectSingle__single-value
        color: red
      & .SelectSingle__single-value--is-disabled
        color: black !important

    .SelectSingle__placeholder, .SelectSingle__single-value
      font-size: 18px
      font-weight: 300 !important

    .SelectSingle__indicator-separator
      display: none

    .SelectSingle__indicator
      padding-right: 13px !important
      padding-left: 0 !important

    .SelectSingle__option
      .Form-Select--White
        color: #000

    .SelectSingle__option--is-selected
      color: #fff

    .SelectSingle--Light
      .SelectSingle__menu
        background-color: $color-background !important
      .SelectSingle__indicator
        & svg path
          stroke: white
      .SelectSingle__option--is-selected
        background-color: $color-dark-input-background

      .SelectSingle__option--is-focused
        background-color: $color-dark-input-background
      .SelectSingle__single-value
        color: white !important
        font-weight: 300 !important
      .SelectSingle__placeholder
        color: white

.exchange-select
  .dropdown
    min-width: 138px
    margin-right: 9px
    .dropdown-menu
      .dropdown-item
        line-height: normal
        min-height: 47px
        display: flex
        align-items: center
        justify-content: center
        &:hover
          background-color: #3F3D41
          color: white
    .b-select
      border: none
      min-width: 138px
      text-align: center !important
      justify-content: center
      padding-right: 16px
      & span
        padding-right: 0 !important
