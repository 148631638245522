.details {
  display: flex;
  flex-direction: column;
  gap: 33px;
  padding-left: 55px;
  max-width: 450px;
  width: 100%;
  position: relative;
  padding-left: 51px;
}

@media (max-width: 1124px) {
  .details {
    padding-left: 24px;
  }
}

@media (max-width: 992px) {
  .details {
    padding-top: 42px;
    max-width: 100%;
    gap: 17px;
  }
}

.details::before {
  position: absolute;
  content: "";
  background-color: var(--grey-3);
  width: 1px;
  height: 124%;
  left: 0;
  top: -24px;
}

.details::before {
  top: -36px;
  height: 113%;
}

@media (max-width: 992px) {
  .details::before {
    left: 35px;
  }
}

@media (max-width: 992px) {
  .details::before {
    top: 0;
    left: -35px;
    width: 116%;
    height: 1px;
  }
}

@media (max-width: 768px) {
  .details {
    padding-left: 0;
  }
}

.details__title {
  font-size: 22px;
  font-family: "MacPawFixelDisplay-SemiBold";
  line-height: normal;
  margin: 0 0 21px;
}

@media (max-width: 992px) {
  .details__title {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .details__title {
    font-size: 18px;
  }
}

.details-form {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.details-form__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.details-form__numbs {
  display: flex;
  flex-direction: column;
}
.details-form__numbs span {
  display: block;
  font-size: 22px;
  line-height: normal;
  font-family: "MacPawFixelDisplay-Regular";
  opacity: 0.9;
}

.details-form svg {
  margin: 0 10px;
  transform: translateY(-1px);
}

.details-form__info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.details-form__info {
  font-size: 18px;
  margin: 0;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
}

.details-form__info p span {
  margin-left: 5px;
}

.details-form__info a {
  display: block;
  text-decoration: underline;
  font-size: 16px;
  font-family: "MacPawFixelDisplay-Regular";
  line-height: normal;
  color: var(--primary-purple);
}

.details-list {
  list-style: none;
  margin: 0;
  padding: 22px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  .details-list {
    gap: 24px;
  }
}

.details-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: normal;
  gap: 15px;
}

@media (max-width: 768px) {
  .details-list li {
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
  .details-form__info {
    text-align: center;
  }
}

.details-list li p {
  font-family: "MacPawFixelDisplay-SemiBold";
  opacity: 0.5;
  margin: 0;
}

.details-list li span {
  font-family: "MacPawFixelDisplay-Regular";
  opacity: 1;
  text-transform: uppercase;
  margin-right: 5px;
  text-align: right;
}

.details__bottom {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: auto;
  font-size: 21px;
  font-family: "MacPawFixelDisplay-Bold";
  line-height: normal;
  text-transform: uppercase;
  color: var(--primary-purple);
}
@media (max-width: 992px) {
  .details__bottom {
    text-align: center;
    justify-content: center;
    margin-top: 22px;
  }
  .details-form__numbs {
    align-items: flex-start !important;
    flex-direction: row;
    gap: 10px;
  }
}

@media (max-width: 570px) {
  .details-form__numbs {
    flex-direction: column;
  }
}
.details__bottom svg {
  transform: translateX(2px);
}
